import React, { useEffect } from 'react'

const ConfirmationModal = ({ label, onConfirm }) => {



  return (
    <>


      <div className="modal fade" id="confirmationModal" tabindex="-1" aria-labelledby="successModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="successModalLabel">{label ? label : "Confirmation"}</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">Do You Want to Sign Out? </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={onConfirm}>yes</button>
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ConfirmationModal