// import React, { useRef } from 'react';
// import Icons from '../../components/ui/Icons';
// import Api from '../../constants/Api';
// import ReactToPrint from 'react-to-print';
// import logo from '../../assets/images/logo_sudikshya.png'
// import { convertToFiveDigits } from '../../utils/ConvertToFive';

// const IdCardModal = ({ item }) => {
//     const modalId = "idcardDownloadModal" + item.associate.id;
//     const modalBodyRef = useRef();

//     const createdDate = new Date(item.associate.createdAt);

//     // Get the month and year in mm/yy format
//     const formattedDate = `${(createdDate.getMonth() + 1).toString().padStart(2, '0')}/${createdDate.getFullYear() % 100}`;

//     // Get the mm/yy of one year in the future
//     const futureDate = new Date(createdDate);
//     futureDate.setFullYear(futureDate.getFullYear() + 1);
//     const futureFormattedDate = `${(futureDate.getMonth() + 1).toString().padStart(2, '0')}/${futureDate.getFullYear() % 100}`;

//     const ModalBody = () => (


//         <div className="modal-body p-0" id={modalId + "ModalBody"} ref={modalBodyRef}>
//             {/* Your existing modal body content */}
//             <div className='w-100 bg-primary mb-3' style={{ height: 10 }}>
//                 <div className="bg-warning h-100 w-50 mx-auto"></div>
//             </div>

//             {/* <div className="d-flex justify-content-between mb-4 px-3">
//                 <div>Ref. ID: {item?.sponsor?.id}</div>
//                 <div>Date of Joiningf: {item.associate.createdAt}</div>
//             </div> */}

//             <div className="d-flex px-3 mb-5">

//                 <div className='flex-grow-1 d-flex flex-column justify-content-center align-items-center'>
//                     <img src={logo} alt="" className='w-50 ' />
//                     <h5 className='fw-bold text-center text-primary text-uppercase  mt-1'>SUDIKSHYA LEARNING PVT LTD.</h5>
//                     <p>Cin:  {item?.associate?.id && `S3A${convertToFiveDigits(item?.associate?.id)}`}</p>


//                     <img id={'#image' + modalId} src={Api.BASE_URL + `${item?.associate?.passPhoto}`} className='border border-primary border-5 rounded-3 object-fit-cover mt-2' style={{ width: 120, height: 150 }} alt="profile" />


//                     <h4 className='fw-bold text-primary text-uppercase mt-2'> {item?.associate?.name}</h4>
//                     <p className='mb-2'> {item?.designation?.name}</p>

//                     <div className="d-flex flex-column">
//                         <div className='d-flex gap-3'><div>Associate ID :</div>  <div>{item?.associate?.id && `S3A${convertToFiveDigits(item?.associate?.id)}`}</div></div>
//                         <div className='d-flex gap-3'><div>Date of Birth :</div>  <div>{item?.associate?.dob}</div></div>
//                         <div className='d-flex gap-3'><div>Phone Number :</div>  <div>{item?.associate?.mobileNumber}</div></div>



//                         <div className=' d-flex justify-content-between gap-2'>

//                             <div className='d-flex text-sm align-items-center gap-2'><div className='d-flex flex-column'><span className=''>valid</span><span className=''>from</span></div> <h3 className='text-primary'>{formattedDate}</h3></div>
//                             <div className='d-flex text-sm align-items-center gap-2'><div className='d-flex flex-column'><span className=''>valid</span><span className=''>upto</span></div> <h3 className='text-primary'>{futureFormattedDate}</h3></div>


//                         </div>

//                        <p className='align-self-end mt-4'> Authorized Signature</p>
//                     </div>



//                 </div>


//             </div>
//         </div>
//     );

//     return (
//         <>

//             <div className='bg-primary rounded text-center px-2 py-1 text-nowrap'>
//                 <span className='' data-bs-toggle="modal" data-bs-target={"#" + modalId}>{Icons.view()}</span>
//             </div>

//             <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">
//                 <div className="modal-dialog  modal-dialog-centered">
//                     <div className="modal-content">
//                         <div className="modal-header">
//                             <h1 className="modal-title fs-5" id={modalId + "Label"}>{item.associate.name}'s Id Card</h1>
//                             <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                         </div>
//                         <ModalBody />
//                         <div className="modal-footer">
//                             {/* <ReactToPrint
//                                 trigger={() => (
//                                     <div className='bg-primary  rounded text-center px-2 py-1 text-nowrap'>
//                                         <span className="text-white me-1">Download</span> <span>{Icons.download('')}</span>
//                                     </div>
//                                 )}
//                                 content={() => modalBodyRef.current}
//                             /> */}

//                             <ReactToPrint
//                                 trigger={() => (
//                                     <div className='bg-primary  rounded text-center px-2 py-1 text-nowrap'>
//                                         <span className="text-white me-1">Download</span> {Icons.download('')}
//                                     </div>
//                                 )}
//                                 content={() => modalBodyRef.current}
//                                 pageStyle={`@page { size: A4; margin: 0; }`}
//                             />

//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default IdCardModal;


import React, { useRef } from 'react';
import Icons from '../../components/ui/Icons';
import Api from '../../constants/Api';
import ReactToPrint from 'react-to-print';
import logo from '../../assets/images/logo_sudikshya.png'
import { convertToFiveDigits } from '../../utils/ConvertToFive';
import html2canvas from 'html2canvas';

const IdCardModal = ({ item }) => {
    const modalId = "idcardDownloadModal" + item.associate.id;
    const modalBodyRef = useRef();

    const createdDate = new Date(item.associate.createdAt);

    // Get the month and year in mm/yy format
    const formattedDate = `${(createdDate.getMonth() + 1).toString().padStart(2, '0')}/${createdDate.getFullYear() % 100}`;

    // Get the mm/yy of one year in the future
    const futureDate = new Date(createdDate);
    futureDate.setFullYear(futureDate.getFullYear() + 1);
    const futureFormattedDate = `${(futureDate.getMonth() + 1).toString().padStart(2, '0')}/${futureDate.getFullYear() % 100}`;

    const ModalBody = () => (
        <div className="modal-body p-0" id={modalId + "ModalBody"} ref={modalBodyRef}  >
            {/* Your existing modal body content */}
            <div className="card profile-card-3">
                <div className="background-block">
                    <div className="text-end p-2"><button
                        type="button"
                        className="btn-close bg-white p-2"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button></div>
                    <img src="https://i.imgur.com/PwpKzmN.png" />
                    <h5 className="fw-bold text-center text-white text-uppercase mt-1">SUDIKSHYA LEARNING PVT LTD.</h5>
                    <p className="text-light fw-light" style={{ fontSize: '14px' }}>CIN: {item?.associate?.serialNo && `S3A${convertToFiveDigits(item?.associate?.serialNo)}`}</p>
                </div>
                <div className="profile-thumb-block"><img alt="profile-image" className="profile" style={{ width: 130, height: 130 }} src={Api.BASE_URL + `${item?.associate?.passPhoto}`} /></div>
                <div className="card-content">
                    <h2 className="text-primary">{item?.associate?.name}<small>{item?.designation?.name}</small></h2>
                    <div className="d-flex flex-column" style={{ maxWidth: '360px', margin: 'auto', marginTop: '20px', marginBottom: '0' }}>
                        <div className="d-flex gap-3">
                            <div>
                                Associate ID :
                            </div>
                            <div>
                                {item?.associate?.serialNo && `S3A${convertToFiveDigits(item?.associate?.serialNo)}`}
                            </div>
                        </div>
                        <div className="d-flex gap-3">
                            <div>
                                Date of Birth :
                            </div>
                            <div>
                                {item?.associate?.dob}
                            </div>
                        </div>
                        <div className="d-flex gap-3">
                            <div>
                                Phone Number :
                            </div>
                            <div>
                                {item?.associate?.mobileNumber}
                            </div>
                        </div>
                        <div className="d-flex justify-content-between gap-2">
                            <div className="d-flex text-sm align-items-center gap-2 mt-2">
                                <div className="d-flex flex-column" style={{ fontSize: '12px', textTransform: 'capitalize', textAlign: 'start' }}>
                                    <span style={{ marginBottom: '-5px' }}>VALID</span><span>THRU</span>
                                </div>
                                <h5 className="text-primary fw-bold" style={{ height: '18px', overflow: 'hidden', display: 'flex', alignItems: 'center' }}>{futureFormattedDate}</h5>
                            </div>
                        </div>
                        <img src="https://i.imgur.com/ZfznklN.jpeg" style={{ maxWidth: '120px', margin: 'auto', marginRight: '30px', marginBottom: '-42px', marginTop: '-38px', position: 'relative', zIndex: '-1', transform: 'rotate(20deg)', filter: 'brightness(1.1)' }} />
                        <p className="align-self-end mt-4">Authorized Signature</p>
                    </div>
                </div>
                <div className="card-footer">
                    <div>
                        <p>Toll Free: 1800 103 5040</p>
                    </div>
                </div>
            </div>
        </div>
    );

    const downloadAsPNG = () => {
        html2canvas(modalBodyRef.current)
            .then(function (canvas) {
                var link = document.createElement('a');
                link.download = 'id_card.png';
                link.href = canvas.toDataURL("image/png");
                link.click();
            });
    };

    return (
        <>
            <style>
                {`
                .profile-card-3 {
                    font-family: 'Open Sans', Arial, sans-serif;
                    position: relative;
                    float: left;
                    overflow: hidden;
                    width: 100%;
                    text-align: center;
                    border: none;
                    border-radius: 0;
                    background: #ffffff;
                }
                .profile-card-3 .background-block {
                    width: 100%;
                    background: linear-gradient(179deg, #310054, #9503ce);
                    border-radius: 0 0 100% 100%;
                    padding-bottom: 100px;
                }
                .background-block img {
                    max-width: 225px;
                    margin: 20px;
                }
                .profile-card-3 .profile {
                    border-radius: 50%;
                    max-width: 150px;
                    opacity: 1;
                    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.5);
                    border: 2px solid rgba(255, 255, 255, 1);
                    margin-top: -70px;
                    margin-bottom: 20px;
                }
                .profile-card-3 .card-content {
                    width: 100%;
                    padding: 15px 25px;
                    color: #232323;
                    float: left;
                    background: #ffffff;
                    height: 50%;
                    border-radius: 0 0 5px 5px;
                    position: relative;
                    z-index: 9999;
                }
                .profile-card-3 h2 {
                    margin: 0 0 5px;
                    font-weight: 600;
                    font-size: 25px;
                }
                .profile-card-3 h2 small {
                    display: block;
                    font-size: 15px;
                    color: #000;
                }
                .card-footer:before {
                    content: '';
                    width: 100%;
                    height: 25px;
                    background: #70018b;
                    display: block;
                    position: absolute;
                    left: 0;
                    top: -15px;
                    border-radius: 100% 100% 0 0;
                }
                .card-footer {
                    background: #70018b;
                    color: #fff;
                    margin-top: 30px;
                    position: relative;
                }
                .card-footer p {
                    position: relative;
                    top: -5px;
                }
                `}
            </style>



            <div className=' rounded p-2 w-100 d-flex flex-column align-items-center ' style={{ backgroundColor: '#D9D9D9' }} data-bs-toggle="modal" data-bs-target={"#" + modalId}>

                <img src="https://i.imgur.com/7Fm6WWA.png" alt="" style={{ width: '100%' }} />
                <p className='fw-bold fs-6 my-1' >Id Card</p>
            </div>


            <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content rounded-3 overflow-hidden" >
                        {/* <div className="modal-header">
                            <h1 className="modal-title fs-5" id={modalId + "Label"}>{item.associate.name}'s Id Card</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div> */}
                        <ModalBody />

                    </div>
                </div>
            </div>
        </>
    );
};

export default IdCardModal;
