import React from "react";
import {
  useGetAssociateByTokenQuery,
  useGetAssociateIdQuery,
} from "../services/associateSlice";
import { Link, useParams } from "react-router-dom";
import Input from "../components/form/Input";
import Loading from "../components/ui/Loading";
import ApiErrorModal from "../components/modals/ApiErrorModal";
import { useSelector } from "react-redux";
import Api from "../constants/Api";
import bgTopImg from "../assets/images/setting_top.png";
import SignOutMobile from "../components/ui/SignOut";
import { convertToFiveDigits } from "../utils/ConvertToFive";
import Icons from "../components/ui/Icons";

const Settings = () => {
  const token = useSelector((state) => state.auth.authToken);

  const res = useGetAssociateByTokenQuery(token);

  // if (res.isLoading) return <Loading />
  if (res.isError) return <ApiErrorModal response={res} />;

  const dataClass = "row border px-4 py-3 m-2 rounded-pill mb-1";

  return (
    <>
      <div className="bg-primary text-white p-4 d-flex  align-items-center">
        <Link to={-1}>
          {Icons.back("text-white", { width: 32, height: 32 })}
        </Link>
        <h2 className="fw-bold ps-3">Settings</h2>
      </div>

      <img src={bgTopImg} className="w-100" alt="" />

      <div className="text-center w-100 mb-4" style={{ marginTop: -60 }}>
        <img
          className="border rounded-circle bg-white"
          src={
            res.data?.associate?.passPhoto
              ? Api.BASE_URL + res.data?.associate?.passPhoto
              : "https://i.imgur.com/8r3A5EK.png"
          }
          alt="not found"
          style={{ width: 150, height: 150 }}
        />
      </div>
      <div className="container">
        <div className={dataClass}>{`S3A${convertToFiveDigits(
          res.data?.associate?.serialNo
        )}`}</div>
        <div className={dataClass}>{res.data?.associate?.name}</div>
        <div className={dataClass}>{res.data?.associate?.mobileNumber}</div>
        <div className={dataClass}>{res.data?.associate?.dob}</div>
        <SignOutMobile />
      </div>
    </>
  );
};

export default Settings;
