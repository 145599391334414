import React, { useEffect } from "react";
import { useLoginAssociateMutation } from "../../services/associateSlice";
import SuccessModal from "../../components/modals/SuccessModal";
import ApiErrorModal from "../../components/modals/ApiErrorModal";
import BachchaPicContainer from "./BachchaPicContainer";

const Login = ({ setPhoneNumber, setStep }) => {
  const [login, loginResponse] = useLoginAssociateMutation();

  const onSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    if (form.checkValidity()) {
      const formData = new FormData();
      const mobileNumber = form["mobileNumber"].value;
      formData.append("mobileNumber", mobileNumber);

      login(formData).then((res) => {
        if (!res.error) {
          setPhoneNumber(mobileNumber);
          if (res?.data?.status !== "Password") setStep("otp");
          else setStep("password");
        }
      });
    } else {
      form.classList.add("was-validated");
    }
  };

  useEffect(() => {
    const classList = document.body.classList;
    if (classList.contains("modal-open")) classList.remove("modal-open");

    const backdrops = document.getElementsByClassName("modal-backdrop");
    for (const backdrop of backdrops) backdrop.remove();
  }, []);

  return (
    <BachchaPicContainer>
      <style>
        {`
                body {
                    background: #70018b;
                }
                input.form-control {
                    padding: 15px 30px;
                    border-radius: 18px !important;
                }
                .btn.btn-primary {
                    padding: 10px;
                }
                `}
      </style>
      <form
        onSubmit={onSubmit}
        noValidate
        style={{
          backgroundColor: "#f9e2ff",
          position: "fixed",
          borderRadius: "20px 20px 0px 0px",
          width: "100%",
          bottom: 0,
        }}
      >
        {loginResponse.isError && <ApiErrorModal response={loginResponse} />}
        <div className="container p-4">
          {/* <label htmlFor="exampleFormControlInput1" className="form-label fs-3 fw-bold text-primary mb-3">Login/Register</label> */}
          <h2 class="fw-bold text-primary fs-1 mb-1">Login/Register</h2>
          <p class="text-secondary fs-6 mb-4">
            Login/Register Into Your Account.
          </p>
          <input
            type="number"
            className="form-control form-control-lg border bg-white border-2 rounded-4 mb-3"
            min={1111111111}
            max={9999999999}
            id="mobileNumber"
            name="mobileNumber"
            placeholder="Mobile Number"
            required
          />
          {loginResponse.isLoading ? (
            <button
              class="btn btn-lg btn-primary rounded-4 w-100"
              type="button"
              disabled
            >
              <span
                class="spinner-border spinner-border-sm"
                aria-hidden="true"
              ></span>
              <span role="status"> Proceeding...</span>
            </button>
          ) : (
            <button
              className="btn btn-lg btn-primary rounded-4 w-100"
              type="submit"
            >
              Proceed
            </button>
          )}
        </div>
      </form>
    </BachchaPicContainer>
  );
};

export default Login;
