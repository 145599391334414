import React from "react";

const Input = ({
  name,
  label,
  type,
  containerClass,
  inputClass,
  ...attributes
}) => {
  const containerClassName = "mb-3" + (containerClass ? containerClass : "");
  const inputClassName =
    "form-control form-control-lg rounded-pill px-3 border-primary border-2" +
    (inputClass ? inputClass : "");
  const inputType = type ? type : "text";

  return (
    <div className={containerClassName}>
      <label className="mb-1 text-muted" htmlFor={name}>
        {label || name}
      </label>
      <input
        type={inputType}
        className={inputClassName}
        id={name}
        name={name}
        {...attributes}
      />
    </div>
  );
};

export default Input;
