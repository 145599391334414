import React from 'react';

const AddharInput = ({ name, placeholder, type, containerClass, inputClass, ...attributes }) => {
  const containerClassName = "mb-3" + (containerClass ? ` ${containerClass}` : "");
  const inputClassName = "form-control form-control-lg rounded-pill px-3 border-primary border-2" + (inputClass ? ` ${inputClass}` : "");
  const inputType = type ? type : "text";

  const formatAadharNumber = (value) => {
    // Remove non-numeric characters
    const numericValue = value.replace(/[^0-9]/g, '');

    // Add hyphens every 4 digits
    let formattedValue = '';
    for (let i = 0; i < numericValue.length; i++) {
      if (i > 0 && i % 4 === 0) {
        formattedValue += '-';
      }
      formattedValue += numericValue[i];
    }

    // Limit the length to 14 characters (12 digits + 2 hyphens)
    return formattedValue.slice(0, 14);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = formatAadharNumber(inputValue);
    e.target.value = formattedValue;

    // If you want to pass the formatted value to the parent component, uncomment the line below
    // attributes.onChange(formattedValue);
  };

  return (
    <div className={containerClassName}>
      <label className='ms-3 mb-1 text-muted' htmlFor={name}>{name}</label>
      <input
        type={inputType}
        className={inputClassName}
        id={name}
        name={name}
        placeholder={placeholder ? placeholder : name}
        onChange={handleInputChange}
        {...attributes}
      />
    </div>
  );
};

export default AddharInput;
