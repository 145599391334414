import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";


export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getDesignations: builder.query({ query: () => `/designation`, keepUnusedDataFor: 0 }),
        postDesignation: builder.mutation({ query: (body) => ({ url: `/designation/create`, method: 'POST', body }) }),

       

    }),
})

export const {useGetDesignationsQuery, usePostDesignationMutation} = extendedApiSlice
