import React from 'react'
import ErrorModal from './ErrorModal'
import { useCheckOtpMutation, useVerfiyPasswordMutation } from '../../services/associateSlice';
import { setAuthToken } from '../../redux/authSlice';
import { useDispatch, useSelector } from 'react-redux';

const ApiErrorModal = ({ response }) => {

    const error = response.isError ? response.error : null
    const [verifyPassword, verifyPasswordResponse] = useVerfiyPasswordMutation({ fixedCacheKey: 'signInUser' });
    const [checkOtp, checkOtpResponse] = useCheckOtpMutation({ fixedCacheKey: 'signInUser' });
    const dispatch = useDispatch();


    const signOut = () => {
        verifyPasswordResponse.reset()
        checkOtpResponse.reset()
        
    dispatch(setAuthToken(null))
        return;
    }

    if (error) {
        if (error?.status === "FETCH_ERROR") return <ErrorModal label={"No Internet"} message={"Kindly check your internet connection."} />
        else if (error?.status === 401) signOut();
        else if (error?.data?.messages.error) return <ErrorModal label={"Bad Request"} message={error?.data?.messages?.error} />
        else if (error?.status === 400) return <ErrorModal label={"Bad Request"} message={error?.data?.message} />
        else if (error?.data?.message) return <ErrorModal label={"Bad Request"} message={error?.data?.message} />
        else return <ErrorModal label={"Error"} message={"Something went wrong"} />
    }

    return;
}

export default ApiErrorModal