import React, { useState } from "react";
import {
  useLoginAssociateMutation,
  useResetPasswordMutation,
  useVerfiyPasswordMutation,
} from "../../services/associateSlice";
import BachchaPicContainer from "./BachchaPicContainer";
import OTPInput from "react-otp-input";
import ApiErrorModal from "../../components/modals/ApiErrorModal";
import Loading from "../../components/ui/Loading";
import { useNavigate } from "react-router-dom";
import { setAuthToken } from "../../redux/authSlice";
import { useDispatch } from "react-redux";

const Password = ({ phoneNumber, setStep }) => {
  const [password, setPassword] = useState("");
  const [verifyPassword, verifyPasswordResponse] = useVerfiyPasswordMutation();
  const [login, loginResponse] = useLoginAssociateMutation();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [resetPassword, resetPasswordResponse] = useResetPasswordMutation();

  const handleChange = (otp) => setPassword(otp.replace(/\D/g, ""));

  const onSubmit = () => {
    verifyPassword({ mobileNumber: phoneNumber, password }).then((res) => {
      if (!res.error) {
        dispatch(setAuthToken(res.data.token));
      }
    });
    navigate("/");
  };

  const changePassword = () => {
    resetPassword({ mobileNumber: phoneNumber }).then((resp) => {
      if (resp?.data?.status === "Password reset successfully") {
        const formData = new FormData();
        formData.append("mobileNumber", phoneNumber);
        login(formData).then((res) => {
          if (!res.error) {
            if (res?.data?.status !== "Password") setStep("otp");
            else setStep("password");
          }
        });
      }
    });
  };

  if (loginResponse.isLoading) return <Loading />;
  if (resetPasswordResponse.isLoading) return <Loading />;

  return (
    <BachchaPicContainer withoutPic>
      <style>
        {`
                    body {
                        background: #f9e2ff;
                    }
                    .bg-header-auth{
                      background:#70018b;
                      padding: 40px 0px !important;
                      border-radius: 0px 0px 20px 20px;
                    }
                    input.form-control {
                        padding: 15px 30px;
                        border-radius: 18px !important;
                    }
                    .btn.btn-primary {
                        padding: 10px;
                    }
                    
                    `}
      </style>

      <div className="h-100 d-flex flex-column align-items-center justify-content-between fw-bold text-primary flex-grow-1">
        <div className="text-center mt-5">
          {resetPasswordResponse.isError && (
            <ApiErrorModal response={resetPasswordResponse} />
          )}
          {loginResponse.isError && <ApiErrorModal response={loginResponse} />}
          {verifyPasswordResponse.isError && (
            <ApiErrorModal response={verifyPasswordResponse} />
          )}

          <h3 className="fw-bold">Enter Access Pin</h3>
          <div className="text-muted mb-5">
            Enter your access pin to continue
          </div>

          <h5 className="fw-bold mb-3">Access Pin</h5>
          {/* <OTPInput
                    value={password}
                    onChange={handleChange}
                    numInputs={4}
                    containerStyle="d-flex justify-content-center mb-3"
                    renderSeparator={<span style={{ width: 10 }}></span>}
                    inputType='password'
                    inputStyle="form-control form-control-lg text-center"
                    skipDefaultStyles
                    renderInput={(props) => <input {...props} style={{ width: 50 }} />}
                /> */}

          <OTPInput
            value={password}
            onChange={handleChange}
            numInputs={4}
            containerStyle="d-flex justify-content-center mb-3 "
            renderSeparator={<span style={{ width: 10 }}></span>}
            inputType={"password"}
            inputmode="numeric"
            pattern="[0-9]*"
            inputStyle="form-control form-control-lg text-center p-3"
            skipDefaultStyles
            renderInput={(props) => (
              <input {...props} style={{ width: 60, height: 60 }} />
            )}
          />

          <div className="text-muted">
            Forgot Pin?{" "}
            <button
              type="button"
              class="btn btn-primary rounded-5 py-2 px-4 ms-2"
              onClick={changePassword}
              fdprocessedid="s5c1r"
            >
              Reset Now
            </button>
          </div>
        </div>

        <div className="text-center w-100 mt-4">
          {verifyPasswordResponse.isLoading ? (
            <button
              className="btn btn-lg btn-primary w-75 mt-auto rounded-4 mb-3 text-center"
              type="button"
              disabled
            >
              <span
                className="spinner-border spinner-border-sm"
                aria-hidden="true"
              ></span>
              <span role="status"> Proceeding...</span>
            </button>
          ) : (
            <button
              className="btn btn-lg btn-primary w-75 mt-auto rounded-4 mb-3"
              type="button"
              disabled={password.length !== 4}
              onClick={onSubmit}
            >
              Proceed
            </button>
          )}
        </div>
      </div>
    </BachchaPicContainer>
  );
};

export default Password;
