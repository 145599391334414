import React from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useGetAssociateByTokenQuery } from "../../services/associateSlice";
import { useSelector } from "react-redux";
import ApiErrorModal from "../../components/modals/ApiErrorModal";
import User from "../../assets/images/user.png";
import Team from "../../assets/images/team.png";
import Student from "../../assets/images/student.png";
import Lead from "../../assets/images/leads.png";
import Document from "../../assets/images/document.png";
import { useGetDesignationsQuery } from "../../services/designationSlice";
import Headbar from "../../components/layout/Headbar";
import PageTransition from "../../components/PageTransition";
const Home = () => {
  const token = useSelector((state) => state.auth.authToken);

  const res = useGetAssociateByTokenQuery(token);

  const designation = useGetDesignationsQuery();

  const navigate = useNavigate();

  // if (res.isLoading || designation.isLoading) return <Loading />
  if (res.isError) return <ApiErrorModal response={res} />;

  const designationName = designation.data?.find(
    (item) => item.id === res.data?.associate?.designationId
  )?.name;

  return (
    <div className="">
      <Headbar id={res?.data?.associate?.id} />
      <style>
        {`
            body {
              background: #f9e2ff;
          }
          input.form-control {
              padding: 15px 30px;
              border-radius: 18px !important;
          }
          .btn.btn-primary {
              padding: 10px;
          }
          .cardflexapp a {
              padding: 5px 20px !important;
          }
          .cardflexapp {
              display: flex;
              align-items: center;
              padding: 15px;
              border: 1px solid #00000020 !important;
              border-radius: 20px;
              margin: 15px 0;
              gap: 18px;
              background: #fff;
          }
          .cardflexapp .leftflexapp h2 {
              color: #70018b;
              font-weight: 900;
              margin-bottom: 10px;
              line-height: 1;
          }
          .cardflexapp .leftflexapp p {
              line-height: 1.2;
              margin-bottom: 10px;
              color: #00000090;
          }
          .cardflexapp .leftflexapp a {
              border-radius: 20px;
          }
          a.notifbtn{
              background: #fff;
              padding: 10px;
              min-height: 50px;
              min-width: 50px;
              border-radius: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              transform: rotate(20deg);
          }
          a.notifbtn svg path:first-child{
              fill: #F3AB49;
          }
          span.notificationdot {
              display: block;
              background: red;
              border-radius: 20px;
              position: absolute;
              top: 4px;
              left: -8px;
              padding: 2px 7px;
              color: #fff;
              font-size: 12px;
              transform: rotate(-20deg);
          }
          .bg-bottombar {
              background: #70018b;
              margin-left: 10px;
              margin-right: 10px;
              margin-top: 5px;
              margin-bottom: 5px;
              border-radius: 50px;

          }
          .bg-bottombar a.nav-link {
              display: flex;
              align-items: center;
              flex-direction: column;
              justify-content: center;
              padding: 6px;
          }
          .bg-bottombar .nav-link p {
              font-size: 12px;
              margin-top: 5px;
          }
          
          `}
      </style>

      <div className="bg-primary p-5 pt-4 px-3 rounded-bottom-5">
        <div className="profile-card text-center text-white d-flex align-items-center">
          {/* <img className='border border-black rounded-circle  profile-img me-3  ' src={res.data?.associate?.passPhoto ? Api.BASE_URL + res.data?.associate?.passPhoto : 'https://i.imgur.com/8r3A5EK.png'} alt="not found" style={{ width: '80px', height: '80px', }} /> */}
          <img
            className="border border-black rounded-circle  profile-img me-3  "
            src={User}
            alt="not found"
            style={{ width: "80px", height: "80px" }}
          />

          <div className="text-start">
            <h3>
              Hello{" "}
              <span className="text-warning fw-bold">
                {res.data?.associate?.name}
              </span>
            </h3>
            <p className="fs-5">Welcome Back!</p>
          </div>
        </div>
      </div>

      <div className="container">
        <div
          className="card border-1 rounded-4 border-primary bg-info p-3 flex-row mx-auto align-items-center mb-4 bg-white"
          style={{ marginTop: "-30px" }}
        >
          {/* <img src={"SurveyIcon"} alt="" className="me-3 " /> */}
          <div>
            <p className="text-black">
              Your <span className="text-warning">Rank</span>
            </p>
            <h4 className="fw-bold text-primary">{designationName}</h4>
          </div>
        </div>
      </div>

      <div className="container mb-3">
        <div className="cardflexapp">
          <div className="leftflexapp">
            <h2>Your Team</h2>
            <p>Stay Updated on Your Team's Progress</p>
            <a
              className="btn btn-primary"
              onClick={() => navigate("/associates/" + res.data.associate.id)}
            >
              My Team
            </a>
          </div>
          <div className="rightflexapp">
            <img src={Team} alt="" style={{ width: 150, height: 150 }} />
          </div>
        </div>
        <div className="cardflexapp">
          <div className="rightflexapp">
            <img src={Student} alt="" style={{ width: 150, height: 150 }} />
          </div>
          <div className="leftflexapp">
            <h2>Your Students</h2>
            <p>Keep Track of Your Students</p>
            <a
              className="btn btn-primary"
              onClick={() => navigate("/students/" + res.data.associate.id)}
            >
              {" "}
              Students
            </a>
          </div>
        </div>
        <div className="cardflexapp">
          <div className="leftflexapp">
            <h2>Leads</h2>
            <p>Efficiently Manage and Track Your Leads</p>
            <a className="btn btn-primary" onClick={() => navigate("/leads")}>
              My Leads
            </a>
          </div>
          <div className="rightflexapp">
            <img src={Lead} alt="" style={{ width: 150, height: 150 }} />
          </div>
        </div>
        <div className="cardflexapp">
          <div className="rightflexapp">
            <img src={Document} alt="" style={{ width: 150, height: 150 }} />
          </div>
          <div className="leftflexapp">
            <h2>Documents</h2>
            <p>Access All Your Documents in One Place</p>
            <a
              className="btn btn-primary"
              onClick={() => navigate("/documents")}
            >
              My Docs
            </a>
          </div>
        </div>
      </div>

      {/* <div className="bg-bottombar fixed-bottom ">
        <div className="d-flex text-white display-1 text-center p-2 m-0 justify-content-center align-items-center">
          <NavLink to={"/"} className="nav-link">{Icons.home()} <p>Home</p></NavLink>
          <NavLink to={"/settings"} className="nav-link">{Icons.settings()}<p>Profile</p></NavLink>
          <NavLink to={"/contactus"} className="nav-link">{Icons.team()} <p>Contact Us</p></NavLink>
        </div>
      </div> */}
    </div>
  );
};

export default Home;
