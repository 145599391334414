import React from "react";
import { Link, useParams } from "react-router-dom";
import userImg from "../../assets/images/user-default.png";
import Loading from "../../components/ui/Loading";
import { useGetAssociateIdQuery } from "../../services/associateSlice";
import ApiErrorModal from "../../components/modals/ApiErrorModal";
import Api from "../../constants/Api";
import Icons from "../../components/ui/Icons";
import { convertToFiveDigits } from "../../utils/ConvertToFive";

const Associate = () => {
  const { id } = useParams();
  const res = useGetAssociateIdQuery(id);

  if (res.isLoading) return <Loading />;
  if (res.isError) return <ApiErrorModal response={res} />;

  const data = res.data;

  return (
    <>
      <div className="bg-primary p-3 rounded-bottom-5">
        <div className="d-flex w-100 align-items-center px-2">
          <Link to={-1}>
            {Icons.back("text-white", { width: 32, height: 32 })}
          </Link>

          <img
            src={Api.BASE_URL + data.associate.passPhoto}
            className="ms-2 bg-danger rounded-circle shadow-sm"
            style={{ height: 50, width: 50 }}
            alt=""
          />

          <div className="flex-grow-1 ps-2 text-light">
            <div>
              Associate ID -{" "}
              <span className="text-warning fw-bold">
                # {`S3A${convertToFiveDigits(data?.associate?.serialNo)}`}
              </span>
            </div>
            <h3 className="fw-bold text-primary text-white">
              {data?.associate?.name}
            </h3>
          </div>
        </div>
      </div>

      <div className="overflow-auto flex-grow-1 w-100 d-flex flex-column">
        <div className="d-flex border-bottom">
          <div className="flex-grow-1 p-3 border-end">
            <div className="fs-13">Designation</div>
            <div className="fw-bold">{data?.designation?.name}</div>
          </div>

          <div className="flex-grow-1 p-3">
            <div className="fs-13">Mobile No.</div>
            <div className="fw-bold">{data?.associate?.mobileNumber}</div>
          </div>
        </div>

        <div className="d-flex border-bottom">
          <div className="flex-grow-1 p-3 border-end">
            <div className="fs-13">Joining Date</div>
            <div className="fw-bold">
              {new Date(data?.associate?.createdAt)
                .toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })
                .replace(/\//g, "-")}
            </div>
          </div>

          <div className="flex-grow-1 p-3 border-end">
            <div className="fs-13">Total Team</div>
            <div className="fw-bold">{data?.totalTeams}</div>
          </div>

          <div className="flex-grow-1 p-3">
            <div className="fs-13">Total Student</div>
            <div className="fw-bold">{data.totalStudents}</div>
          </div>
        </div>

        <div className="d-flex border-bottom">
          <div className="flex-grow-1 p-3">
            <div className="fs-13">Status</div>
            <div className="fw-bold">{data?.associate?.status}</div>
          </div>
        </div>

        <div className="d-flex border-bottom">
          <div className="flex-grow-1 p-3">
            <div className="fs-13">Address</div>
            <div className="fw-bold">
              {data?.address?.flatNumber + ",  "}
              {data?.address?.addressLine1 +
                ",   " +
                data?.address?.landmark +
                ",   " +
                data?.address?.state +
                ",   " +
                data?.address?.pin}
            </div>
          </div>
        </div>

        <div className="d-flex flex-column mt-auto p-3">
          <Link
            className="btn btn-primary rounded-pill mb-3 btn-lg"
            to={"/associate/create/" + id}
          >
            Add Associate for {data?.associate?.name}
          </Link>
          <Link
            className="btn btn-primary rounded-pill mb-3 btn-lg"
            to={"/student/create/" + id}
          >
            Add Student for {data?.associate?.name}
          </Link>
          <div className="d-flex">
            <Link
              className="btn btn-primary rounded-pill btn-lg flex-grow-1 me-3"
              to={`/students/${data?.associate?.id}`}
            >
              View Students
            </Link>
            <Link
              className="btn btn-primary rounded-pill btn-lg flex-grow-1"
              to={`/associates/${data?.associate?.id}`}
            >
              View Team
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Associate;
