import React from 'react';

const SelectInput = ({ name, options, containerClass, inputClass, ...attributes }) => {
  const containerClassName = 'd-flex mb-3 align-items-center ' + (containerClass ? containerClass : '');
  const selectClassName = 'form-select form-select-lg rounded-pill px-3 border-primary border-2 ' + (inputClass ? inputClass : '');

  return (
    <div className={containerClassName}>
      <select className={selectClassName} id={name} name={name} {...attributes}>
        {options &&
          options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
    </div>
  );
};

export default SelectInput;
