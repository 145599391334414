import React, { useState } from 'react';
import Login from './Login';
import Otp from './Otp';
import Password from './Password';

const SignIn = () => {

  const [phoneNumber, setPhoneNumber] = useState(null);
  const [step, setStep] = useState('login'); // 'login', 'otp', 'password'


  switch (step) {
    case 'login': return <Login setPhoneNumber={setPhoneNumber} setStep={setStep} />
    case 'otp': return <Otp phoneNumber={phoneNumber} setStep={setStep} />
    case 'password': return <Password phoneNumber={phoneNumber}  setStep={setStep} />
    default: return <Login setPhoneNumber={setPhoneNumber} setStep={setStep} />
  }

};

export default SignIn;
