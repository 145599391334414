import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";


export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getStudents: builder.query({ query: () => `/student`, keepUnusedDataFor: 0 }),
        getStudent: builder.query({ query: (id) => `/student?id=${id}`, keepUnusedDataFor: 0 }),
        postStudent: builder.mutation({ query: (body) => ({ url: `/student/create`, method: 'POST', body }) }),

        getStudentByAssociate: builder.query({ query: (id) => `/student/byassociate?associateId=${id}`, keepUnusedDataFor: 0 }),
        

    }),
})

export const {useGetStudentQuery, useGetStudentsQuery, usePostStudentMutation ,useGetStudentByAssociateQuery} = extendedApiSlice
