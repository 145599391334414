import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import NewCard from "../../components/ui/NewCard";
import {
  useGetNotificationsQuery,
  useMarkNotificationReadMutation,
} from "../../services/associateSlice";

import hourGlassImg from "../../assets/images/hour-glass.png";
import Icons from "../../components/ui/Icons";
import Loading from "../../components/ui/Loading";

const Notifications = () => {
  const { id } = useParams();

  const response = useGetNotificationsQuery();
  const [markRead, markReadRes] = useMarkNotificationReadMutation();

  const markNotification = async (data) => {
    for (const item of data) {
      try {
        const zoneRes = await markRead(item.id);
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  function formatTimeAgo(datetime) {
    const currentTime = new Date(); // Local time
    const utcCurrentTime = Date.UTC(
      currentTime.getFullYear(),
      currentTime.getMonth(),
      currentTime.getDate(),
      currentTime.getHours(),
      currentTime.getMinutes(),
      currentTime.getSeconds()
    ); // Convert current time to UTC

    const eventTime = new Date(datetime + "Z"); // Assume datetime is in UTC and append 'Z' to indicate it
    const utcEventTime = Date.UTC(
      eventTime.getFullYear(),
      eventTime.getMonth(),
      eventTime.getDate(),
      eventTime.getHours(),
      eventTime.getMinutes(),
      eventTime.getSeconds()
    );

    const diff = utcCurrentTime - utcEventTime; // Difference in milliseconds

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return days === 1 ? "1 day ago" : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
    } else {
      return minutes <= 1 ? "Just Now" : `${minutes} minutes ago`;
    }
  }

  useEffect(() => {
    if (response.isLoading || !response.data) return;

    const userNotification = response.data.filter(
      (item) => item.associateId === id
    );
    const userUnseenNotification = userNotification.filter(
      (item) => item.isRead === "0"
    );

    if (userUnseenNotification.length > 0) {
      const timer = setTimeout(() => {
        markNotification(userUnseenNotification);
      }, 5000); // 5 seconds delay

      return () => clearTimeout(timer); // Clear the timeout if the component unmounts or the timer changes
    }
  }, [response.isLoading, response.data, id, markNotification]);

  if (response.isLoading) return <Loading />;

  const userNotification =
    response.data.filter((item) => item.associateId === id) || [];

  return (
    <>
      <div className="bg-primary p-3 min-4">
        <div className="d-flex w-100 align-items-center px-2">
          <Link to={-1}>
            {Icons.back("text-white", { width: 32, height: 32 })}
          </Link>
          <div className="flex-grow-1 ps-3 text-light">
            <h3 className="fw-bold text-primary text-white">Notifications</h3>
          </div>
        </div>
      </div>
      <div className="container py-3">
        <div className="row row-cols-1 g-2 my-2">
          {userNotification.reverse().map((noti) => (
            <div key={noti.id} className="col card-group">
              <div
                className="d-flex align-items-center "
                style={{ opacity: noti.isRead == "1" ? 0.6 : 1 }}
              >
                <div className="flex-grow-1 px-2 ">
                  <p>{formatTimeAgo(noti.createdAt)} </p>
                  <div className="fs-4 fw-bold text-primary mb-2">
                    {noti?.title}
                  </div>
                  <p className="fs-6 fw-normal mb-2">{noti?.message}</p>
                  {/* <Link className='text-success' to={'/demoTiffin/' + noti.id}>View Details</Link> */}
                </div>
              </div>
              <hr />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Notifications;
