import React from "react";
import { Link } from "react-router-dom";
import Icons from "../components/ui/Icons";
import Call from "../assets/images/call.png";
import Mail from "../assets/images/mail.png";
import Whats from "../assets/images/whats.png";

const ContactUs = () => {
  const handlePhoneNumberClick = () => {
    const phoneNumber = "tel:18001035040";
    const options = "location=yes"; // You can specify additional options here

    if (window.cordova)
      window.cordova.InAppBrowser.open(phoneNumber, "_system", options);
    else window.open(phoneNumber, "_system");
  };

  const handleEmailClick = () => {
    const email = "mailto:support@sudikshya.com";
    const options = "location=yes"; // You can specify additional options here

    if (window.cordova)
      window.cordova.InAppBrowser.open(email, "_system", options);
    else window.open(email, "_system");
  };

  return (
    <>
      <div className="bg-primary p-3 rounded-bottom-5">
        <div className="d-flex w-100 align-items-center px-2">
          <Link to={-1}>
            {Icons.back("text-white", { width: 32, height: 32 })}
          </Link>

          {/* <img src={Api.BASE_URL + data.associate.passPhoto} className='bg-danger rounded-circle shadow-sm' style={{ height: 50, width: 50 }} alt="" /> */}

          <div className="text-white fw-bold fs-3">Contact Us</div>
        </div>
      </div>
      <div className="p-3 p-lg-5">
        <div className="card rounded-4 overflow-hidden shadow p-3 flex-row align-items-center my-2">
          <img
            className="me-2"
            src={Call}
            style={{ maxWidth: "50px" }}
            alt="icon"
          />
          <div className="text-primary">
            <p>Toll Free</p>
            <div
              className="fs-6 fw-bold text-decoration-none"
              to="tel:18001035040"
              onClick={handlePhoneNumberClick}
              target="_blank"
            >
              1800 103 5040
            </div>
          </div>
        </div>
        <div className="card rounded-4 overflow-hidden shadow p-3 flex-row align-items-center my-2">
          <img
            className="me-2"
            src={Mail}
            style={{ maxWidth: "50px" }}
            alt="icon"
          />
          <div className="text-primary">
            <p className="text-primary">E-Mail</p>
            <div
              className="fs-6 fw-bold text-decoration-none"
              to="mailto:support@sudikshya.com"
              onClick={handleEmailClick}
              target="_blank"
            >
              support@sudikshya.com
            </div>
          </div>
        </div>
        <div className="card rounded-4 overflow-hidden shadow p-3 flex-row align-items-center my-2">
          <img
            className="me-2"
            src={Whats}
            style={{ maxWidth: "50px" }}
            alt="icon"
          />
          <div>
            <p className="text-primary">WhatsApp</p>
            <Link
              className="fs-6 fw-bold text-decoration-none"
              to="https://wa.me/7978261251"
              target="_blank"
            >
              7978261251
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
