import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  useGetLeadsQuery,
  useUpdateLeadStatusMutation,
} from "../../services/leadSlice";
import Loading from "../../components/ui/Loading";
import ApiErrorModal from "../../components/modals/ApiErrorModal";
import Icons from "../../components/ui/Icons";
import { useSelector } from "react-redux";
import { useGetAssociateByTokenQuery } from "../../services/associateSlice";
import SelectInput from "../../components/form/SelectInput";
import InputSearch from "../../components/form/InputSearch";

const Leads = () => {
  const token = useSelector((state) => state.auth.authToken);

  const res = useGetAssociateByTokenQuery(token);

  const response = useGetLeadsQuery();
  const [updateStatus, setUpdateStatusResponse] = useUpdateLeadStatusMutation();

  const [searchValue, setSearchyValue] = useState("");
  // const [selectValue, setSelectValue] = useState("")

  if (response.isLoading || res.isLoading) return <Loading />;
  if (response.isError) return <ApiErrorModal response={response} />;

  const handleUpdateStatus = (leadId) => {
    const confirmation = window.confirm("Do you want to update the status?");

    if (confirmation) {
      let status = prompt("Enter Status to Update");

      if (status !== null && status.trim() !== "") {
        updateStatus({ id: leadId, status }).then(() => response.refetch());
      } else {
        alert("Update canceled or empty status entered.");
      }
    } else {
      alert("Update canceled.");
    }
  };

  return (
    <>
      <div className="bg-primary p-3 min-4">
        <div className="d-flex w-100 align-items-center px-2">
          <Link to={-1}>
            {Icons.back("text-white", { width: 32, height: 32 })}
          </Link>

          <div className="flex-grow-1 ps-3 text-light">
            {/* <div>{currentRes.data.associate.name}</div> */}
            <h3 className="fw-bold text-primary text-white">Leads</h3>
          </div>

          <Link to={`../leads/create/${res.data.associate.id}`}>
            {Icons.create("text-white", { width: 40, height: 40 })}
          </Link>
        </div>
      </div>

      <div className="p-3 p-lg-5">
        <div className="mb-3 mx-1 align-items-center">
          <InputSearch
            name={"Search Lead"}
            containerClass={""}
            inputClass={"fs-13"}
            value={searchValue}
            onChange={(e) => setSearchyValue(e.target.value)}
          />
        </div>

        <div className="card rounded-5 overflow-hidden shadow p-3">
          <div className="table-responsive">
            <table className="table table-borderless table-hover">
              <thead>
                <tr className="border-bottom">
                  <th scope="col" className="text-muted">
                    Ld. ID
                  </th>
                  <th scope="col" className="text-muted">
                    Name
                  </th>
                  <th scope="col" className="text-muted">
                    Lead Referrer
                  </th>
                  <th scope="col" className="text-muted">
                    Address
                  </th>
                  <th scope="col" className="text-muted">
                    Mobile Number
                  </th>
                  <th scope="col" className="text-muted">
                    Date
                  </th>
                  <th scope="col" className="text-muted">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {response?.data
                  ?.filter((item) => item.associateId == res.data.associate.id)
                  .filter((item) => {
                    if (searchValue?.length === 0) return true;

                    const isSearchMatch =
                      searchValue?.length > 0 &&
                      (`LD${item?.id}`
                        .toLowerCase()
                        .includes(searchValue.toLowerCase()) ||
                        item?.name
                          .toLowerCase()
                          .includes(searchValue.toLowerCase()) ||
                        item?.associateName
                          .toLowerCase()
                          .includes(searchValue.toLowerCase()) ||
                        item?.mobileNumber
                          .toLowerCase()
                          .includes(searchValue.toLowerCase()) ||
                        item?.address
                          .toLowerCase()
                          .includes(searchValue.toLowerCase()) ||
                        item?.status
                          .toLowerCase()
                          .includes(searchValue.toLowerCase()));

                    return isSearchMatch;
                  })
                  .map((item, i) => (
                    <tr key={i}>
                      <th scope="row">{`LD` + item?.id}</th>
                      <th>{item?.name}</th>
                      <th>{item?.associateName}</th>
                      <th>{item?.address}</th>
                      <th>{item?.mobileNumber}</th>
                      <th>{item?.createdAt}</th>
                      <th className="text-success">
                        {item?.status} <span>{Icons?.upArrow}</span>
                      </th>
                      {/* <td>
                    <button
                      className='btn btn-sm btn-success rounded-pill px-3 text-white'
                      onClick={() => handleUpdateStatus(item.id)}
                    >
                      Update Status
                    </button>
                  </td> */}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Leads;
