import React from "react";
import logoImg from "../../assets/images/logo_sudikshya.png";
import displayImg from "../../assets/images/image 2.png";

const BachchaPicContainer = ({ children, withoutPic }) => {
  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex flex-column p-3 bg-header-auth">
        <img
          src={logoImg}
          alt=""
          className="w-100 mx-auto"
          style={{ maxWidth: 300 }}
        />
        {withoutPic || (
          <img
            src={displayImg}
            alt=""
            className="w-100 mx-auto mt-3"
            style={{ maxWidth: 400 }}
          />
        )}
      </div>
      <div className="h-100">{children}</div>
    </div>
  );
};

export default BachchaPicContainer;
