import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";


export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAssociates: builder.query({ query: () => `/associates`, keepUnusedDataFor: 0 }),
        getAssociateId: builder.query({ query: (id) => `/associates?id=${id}`, keepUnusedDataFor: 0 }),
        getPendingAssociate: builder.query({ query: () => `/associates/pending`, keepUnusedDataFor: 0 }),
        postAssociate: builder.mutation({ query: (body) => ({ url: `/associates/create`, method: 'POST', body }) }),
        updateAssociateStatus: builder.mutation({ query: ({ id, status }) => ({ url: `/associates/updateStatus?id=${id}&status=${status}`, method: 'POST' }) }),
        getAssociateByAssociate: builder.query({ query: (id) => `/associates/byassociate?associateId=${id}`, keepUnusedDataFor: 0 }),
        loginAssociate: builder.mutation({ query: (body) => ({ url: `/associates/login`, method: 'POST', body }) }),
        makePayment: builder.query({ query: ({ id, aName }) => `/api/ccavenue?id=${id}&name=${aName}`, keepUnusedDataFor: 0 }),
        verfiyPassword: builder.mutation({ query: (body) => ({ url: `/associates/verifyPassword`, method: 'POST', body }) }),
        getAssociateByToken: builder.query({ query: (token) => `/associates/getAssociateByToken?token=${token}`, keepUnusedDataFor: 1}),
        
        updateAssociate: builder.mutation({ query: (body) => ({ url: `/associates/associateSignUpUpdate`, method: 'POST', body }) }),
        
        signOut: builder.mutation({ query: (body) => ({ url: `/associates/signOut`, method: 'POST', body }) }),
        
        resetPassword: builder.mutation({ query: (body) => ({ url: `/associates/resetPassword`, method: 'POST', body }) }),
        
        checkOtp: builder.mutation({ query: (body) => ({ url: `/associates/verifyOtp`, method: 'POST', body }) }),
        resendOtp: builder.mutation({ query: (body) => ({ url: `/associates/resetOtp`, method: 'POST', body }) }),
        userOtpSend : builder.mutation({ query: (body) => ({ url: `/associates/userOtpSend`, method: 'POST', body }) }),
        userOtpVerify : builder.mutation({ query: (body) => ({ url: `/associates/userOtpVerify`, method: 'POST', body }) }),

        getNotifications: builder.query({ query: () => `/notifications`, keepUnusedDataFor: 0 }),
        getUnReadNotifications: builder.query({ query: () => `/notifications/unreadNotifications`, keepUnusedDataFor: 0 }),
        markNotificationRead: builder.mutation({ query: (id) => ({ url: `/notifications/markNotificationRead?id=${id}`, method: 'POST' }) }),
        

    }),
})

export const { useGetAssociateIdQuery, useMarkNotificationReadMutation, useGetUnReadNotificationsQuery, useGetAssociatesQuery, useGetNotificationsQuery, useGetPendingAssociateQuery, usePostAssociateMutation, useMakePaymentQuery, useUpdateAssociateStatusMutation, useGetAssociateByAssociateQuery, useLoginAssociateMutation, useCheckOtpMutation, useVerfiyPasswordMutation, useGetAssociateByTokenQuery, useUpdateAssociateMutation, useSignOutMutation ,useResetPasswordMutation ,useResendOtpMutation , useUserOtpSendMutation , useUserOtpVerifyMutation} = extendedApiSlice
