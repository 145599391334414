import React from "react";
import { Link } from "react-router-dom";
import Loading from "../../components/ui/Loading";
import ApiErrorModal from "../../components/modals/ApiErrorModal";
import { useSelector } from "react-redux";
import { useGetAssociateByTokenQuery } from "../../services/associateSlice";
import ImageModal from "../../components/modals/ImageModal";
import Api from "../../constants/Api";
import ProfileModal from "./ProfileModal";
import IdCardModal from "./IdCardModal";
import OfferLetterModal from "./OfferLetterModal";
import Icons from "../../components/ui/Icons";
import { useGetDesignationsQuery } from "../../services/designationSlice";

const Documents = () => {
  const token = useSelector((state) => state.auth.authToken);

  const res = useGetAssociateByTokenQuery(token);

  const desigResponse = useGetDesignationsQuery();

  if (res.isLoading) return <Loading />;
  if (res.isError) return <ApiErrorModal response={res} />;

  return (
    <>
      <div className="bg-primary p-3 rounded-bottom-5">
        <div className="d-flex w-100 align-items-center px-2">
          <Link to={-1}>
            {Icons.back("text-white", { width: 32, height: 32 })}
          </Link>

          <div className="flex-grow-1 ps-3 text-light">
            <h3 className="fw-bold text-primary text-white">My Documents</h3>
          </div>
        </div>
      </div>

      <div className="overflow-auto p-3 flex-grow-1 w-100">
        <div className="container">
          {/* <div className='row  row-cols-2 g-3 '>

                        <ImageModal index={0} src={Api.BASE_URL + res.data.associate.aadharFront} />
                        <ImageModal index={1} src={Api.BASE_URL + res.data.associate.aadharBack} />
                        <ImageModal index={2} src={Api.BASE_URL + res.data.associate.passPhoto} />

                    </div> */}
          <h3 className="row row-cols-1 mt-3 fw-bold text-center">Documents</h3>
          <div className="row my-2 row-cols-2 g-2 ">
            <div className="col">
              <ProfileModal item={res.data} desigName={desigResponse.data} />
            </div>
            {res.data.associate.idCardStatus != "Pending" && (
              <div className="col">
                <IdCardModal item={res.data} />
              </div>
            )}
            {res.data.associate.offerLetterStatus != "Pending" && (
              <div className="col">
                <OfferLetterModal item={res.data} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Documents;
