import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Input from "../../components/form/Input";
import {
  useGetAssociateIdQuery,
  useGetAssociatesQuery,
  usePostAssociateMutation,
  useUserOtpSendMutation,
  useUserOtpVerifyMutation,
} from "../../services/associateSlice";
import { useGetDesignationsQuery } from "../../services/designationSlice";
import Loading from "../../components/ui/Loading";
import ApiErrorModal from "../../components/modals/ApiErrorModal";
import SelectInput from "../../components/form/SelectInput";
import Icons from "../../components/ui/Icons";
import SuccessModal from "../../components/modals/SuccessModal";
import FileInput from "../../components/form/FileInput";
import AddharInput from "../../components/form/AddharInput";
import { useGetPackageQuery } from "../../services/adminSlice";

import { ToastContainer, toast, Bounce } from "react-toastify";
import ShortcutModal from "../../components/modals/ShortcutModal";

const GENDER = {
  MALE: "Male",
  FEMALE: "Female",
  OTHERS: "Others",
};

const QUALIFICATION = {
  PASSED_TEN: "10th Pass",
  PASSED_PLUS_TWO: "+2 Pass",
  PASSED_PLUS_THREE: "+3 Pass",
};

const stateOptions = [
  { label: "Andhra Pradesh", value: "Andhra Pradesh" },
  { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
  { label: "Assam", value: "Assam" },
  { label: "Bihar", value: "Bihar" },
  { label: "Chhattisgarh", value: "Chhattisgarh" },
  { label: "Goa", value: "Goa" },
  { label: "Gujarat", value: "Gujarat" },
  { label: "Haryana", value: "Haryana" },
  { label: "Himachal Pradesh", value: "Himachal Pradesh" },
  { label: "Jharkhand", value: "Jharkhand" },
  { label: "Karnataka", value: "Karnataka" },
  { label: "Kerala", value: "Kerala" },
  { label: "Madhya Pradesh", value: "Madhya Pradesh" },
  { label: "Maharashtra", value: "Maharashtra" },
  { label: "Manipur", value: "Manipur" },
  { label: "Meghalaya", value: "Meghalaya" },
  { label: "Mizoram", value: "Mizoram" },
  { label: "Nagaland", value: "Nagaland" },
  { label: "Odisha", value: "Odisha" },
  { label: "Punjab", value: "Punjab" },
  { label: "Rajasthan", value: "Rajasthan" },
  { label: "Sikkim", value: "Sikkim" },
  { label: "Tamil Nadu", value: "Tamil Nadu" },
  { label: "Telangana", value: "Telangana" },
  { label: "Tripura", value: "Tripura" },
  { label: "Uttar Pradesh", value: "Uttar Pradesh" },
  { label: "Uttarakhand", value: "Uttarakhand" },
  { label: "West Bengal", value: "West Bengal" },
  {
    label: "Andaman and Nicobar Islands",
    value: "Andaman and Nicobar Islands",
  },
  { label: "Chandigarh", value: "Chandigarh" },
  {
    label: "Dadra and Nagar Haveli and Daman and Diu",
    value: "Dadra and Nagar Haveli and Daman and Diu",
  },
  { label: "Lakshadweep", value: "Lakshadweep" },
  { label: "Delhi", value: "Delhi" },
  { label: "Puducherry", value: "Puducherry" },
];

const CreateAssociate = () => {
  const { id } = useParams();
  const res = useGetAssociateIdQuery(id);
  const packageData = useGetPackageQuery(1);

  const navigate = useNavigate();
  const [postAssociate, postAssociateResponse] = usePostAssociateMutation();

  const [userOtpSend, userOtpSendResponse] = useUserOtpSendMutation();
  const [userOtpVerify, userOtpVerifyResponse] = useUserOtpVerifyMutation();

  const getDesignations = useGetDesignationsQuery();
  const getSponsors = useGetAssociatesQuery();

  const [paymentSkipped, setPaymentSkipped] = useState(false);

  // State for OTP functionality
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");

  const [mobileNumber, SetMobileNumber] = useState("");

  const [qualification, setQualification] = useState(QUALIFICATION.PASSED_TEN);
  const [gender, setGender] = useState(GENDER.MALE);
  const [rank, setRank] = useState("");
  const [ranks, setRanks] = useState([]);
  const [sponsor, setSponsor] = useState(id);

  const activeClassName = "btn btn-primary rounded-pill px-4 py-1 me-2 mb-2";
  const inactiveClassName =
    "btn btn-outline-primary rounded-pill px-4 py-1 me-2 mb-2";
  const btnClassName =
    "btn btn-success rounded-pill btn-lg px-5 text-white mt-5 w-100";

  const renderGender = (selected, current) => (
    <div
      className={selected === current ? activeClassName : inactiveClassName}
      onClick={() => setGender(current)}
    >
      {current}
    </div>
  );
  // const renderRank = (selected, current) => <div className={selected === current ? activeClassName : inactiveClassName} onClick={() => setRank(current)}>{current}</div>
  const renderQualification = (selected, current) => (
    <div
      className={selected === current ? activeClassName : inactiveClassName}
      onClick={() => setQualification(current)}
    >
      {current}
    </div>
  );

  const renderRank = (selectedId, current) => (
    <div
      key={current?.id}
      className={
        selectedId === current?.id ? activeClassName : inactiveClassName
      }
      onClick={() => setRank(current?.id)}
    >
      {current?.name}
    </div>
  );
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    if (rank === undefined || rank === null || rank === "") {
      alert("Please select a rank."); // Display an error message or handle as needed
      return; // Exit the function to prevent form submission
    }
    if (form.checkValidity() && otpVerified) {
      const body = new FormData();
      body.append("name", form["Associate Name"].value);
      body.append("mobileNumber", form["Mobile Number"].value);
      body.append("fatherName", form["Father’s Name"].value);
      body.append("dob", form["Date of Birth"].value);
      body.append(
        "alternateMobileNumber",
        form["Alternative Mobile Number"].value
      );
      body.append("aadharNumber", form["Aadhar Number"].value);
      body.append("gender", gender);
      body.append("qualification", qualification);
      body.append("otherQualification", form["Other Qualification"].value);
      body.append("designationId", rank);
      body.append("associateId", form["Sponsor’s Associate ID"].value);
      body.append("personalStudentId", form["Personal Student ID"].value);

      body.append(
        "address[flatNumber]",
        form["Plot/Flat Number or Budling Name"].value
      );
      body.append("address[addressLine1]", form["Address Line 1"].value);
      body.append("address[landmark]", form["Landmark"].value);
      body.append("address[state]", form["State"].value);
      body.append("address[district]", form["Dist"].value);
      body.append("address[cityVillage]", form["City/Village"].value);
      body.append("address[pin]", form["Pincode"].value);

      const aadharFrontFileInput = form["Upload Aadhar Front"];
      const aadharBackFileInput = form["Upload Aadhar Back"];
      const passPhotoFileInput = form["Upload Passphoto"];

      // Check if a file is selected
      if (aadharFrontFileInput?.files?.length > 0) {
        const aadharFrontFile = aadharFrontFileInput.files[0];
        body.append("aadharFront", aadharFrontFile);
      }

      if (aadharBackFileInput?.files?.length > 0) {
        const aadharBackFile = aadharBackFileInput.files[0];
        body.append("aadharBack", aadharBackFile);
      }

      if (passPhotoFileInput?.files?.length > 0) {
        const passPhotoFile = passPhotoFileInput.files[0];
        body.append("passPhoto", passPhotoFile);
      }

      postAssociate(body).then((res) => {
        if (!res.error) {
          if (paymentSkipped) {
            navigate(-1);
          } else {
            navigate(`../../associate/pay/${res.data.associateId}`);
          }
        }
      });
    } else {
      form.classList.add("was-validated");
      setError("Please verify OTP");
    }
  };

  const calculateEighteenYearsAgo = () => {
    const today = new Date();
    const eighteenYearsAgo = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    );
    return eighteenYearsAgo.toISOString().split("T")[0];
  };

  useEffect(() => {
    const computeRanks = (designationId, processedDesignations = new Set()) => {
      let arr = [];
      const designation = getDesignations?.data?.find(
        (item) => item.id == designationId
      );
      const parentDesignationId = designation?.designationId;

      if (!processedDesignations.has(designationId)) {
        arr.push(designation);
        processedDesignations.add(designationId);
      }

      // Add siblings of the current designation
      const siblings = getDesignations?.data?.filter(
        (item) =>
          item.designationId == parentDesignationId &&
          item.id != designationId &&
          !processedDesignations.has(item.id)
      );
      siblings.forEach((sibling) => {
        if (!processedDesignations.has(sibling.id)) {
          arr.push(sibling);
          processedDesignations.add(sibling.id);
        }
      });

      // Add children of the current designation
      const children = getDesignations?.data?.filter(
        (item) =>
          item.designationId == designationId &&
          !processedDesignations.has(item.id)
      );
      children.forEach((child) => {
        if (!processedDesignations.has(child.id)) {
          arr.push(child);
          processedDesignations.add(child.id);
          arr = [...arr, ...computeRanks(child.id, processedDesignations)];
        }
      });

      return arr;
    };

    if (sponsor.length > 0 && getDesignations.isSuccess) {
      setRanks(computeRanks(res?.data?.designation?.id));
    } else {
      if (getDesignations.isSuccess) setRanks(getDesignations.data);
    }
  }, [sponsor, getDesignations.isSuccess]);

  const handleSendOtp = () => {
    if (!/^\d+$/.test(mobileNumber)) {
      alert("Mobile number should contain only digits");
      return;
    }
    userOtpSend({ mobileNumber: mobileNumber, associateId: id }).then((res) => {
      if (!res.error) {
        if (!otpSent) document.getElementById("shortcutButton").click();
        setOtpSent(true);
      }
    });
  };

  const handleVerifyOtp = () => {
    userOtpVerify({ otp: parseInt(otp, 10), associateId: id }).then((res) => {
      if (!res.error) {
        setOtpVerified(true);
      }
    });
  };

  if (getDesignations.isLoading || getSponsors.isLoading || res.isLoading)
    return <Loading />;
  if (getDesignations.isError)
    return <ApiErrorModal response={getDesignations} />;
  if (getSponsors.isError) return <ApiErrorModal response={getSponsors} />;

  const defaultRankId = ranks?.find((item) => item?.isDefault == "1")?.id;

  return (
    <>
      {userOtpSendResponse.isError && (
        <ApiErrorModal response={userOtpSendResponse} />
      )}
      {postAssociateResponse.isError && (
        <ApiErrorModal response={postAssociateResponse} />
      )}
      {postAssociateResponse.isSuccess &&
        toast("Associate Created SuccessFully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        })}

      <div className="bg-primary p-3 min-4">
        <div className="d-flex w-100 align-items-center px-2">
          <Link to={-1}>
            {Icons.back("text-white", { width: 32, height: 32 })}
          </Link>

          <div className="flex-grow-1 ps-3 text-light">
            <h3 className="text-primary text-white">Create Associate</h3>
            <div>
              For{" "}
              {
                getSponsors?.data?.find((item) => item?.associate?.id === id)
                  ?.associate?.name
              }
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-auto p-3 flex-grow-1 w-100">
        <h5 className="text-primary mb-3 fw-bold">Personal Details</h5>
        <form onSubmit={handleSubmit} noValidate>
          <Input name={"Associate Name"} required />
          <div className="">
            <Input
              name={"Mobile Number"}
              type="tel"
              minLength={10}
              maxLength={10}
              value={mobileNumber}
              onChange={(e) => SetMobileNumber(e.target.value)}
              disabled={otpVerified}
              required
            />

            <ShortcutModal
              id={1}
              otpSent={otpSent}
              otpVerified={otpVerified}
              otp={otp}
              setOtp={setOtp}
              handleVerifyOtp={handleVerifyOtp}
              handleSendOtp={handleSendOtp}
            />

            <div className=" mb-4 ">
              {!otpSent && (
                <button
                  type="button"
                  className="btn btn-primary btn-md mt-2 w-100"
                  fdprocessedid="carraw"
                  onClick={handleSendOtp}
                >
                  Send OTP
                </button>
              )}
            </div>

            {/* 
            {otpSent && !otpVerified && (
              <div className=''>
                <Input name={"OTP"} type="text" value={otp} onChange={(e) => setOtp(e.target.value)} required />
                <button type='button' className='btn btn-primary btn-sm float-end ' onClick={handleVerifyOtp}>Verify OTP</button>
              </div>
            )} */}
            {otpVerified && (
              <p className="text-success fw-bold ms-1 mb-1">OTP Verified</p>
            )}
          </div>

          <Input
            name={"Alternative Mobile Number"}
            type="tel"
            minLength={10}
            maxLength={10}
          />
          <Input name={"Father’s Name"} required />
          <Input
            name={"Date of Birth"}
            type={"date"}
            max={calculateEighteenYearsAgo()}
            required
          />
          <h6 className="text-primary mb-2 fw-bold">Gender:</h6>
          <div className="d-flex mb-3">
            {renderGender(gender, GENDER.MALE)}
            {renderGender(gender, GENDER.FEMALE)}
            {renderGender(gender, GENDER.OTHERS)}
          </div>

          <h6 className="text-primary mb-2 fw-bold">Qualifications:</h6>

          <div className="d-flex mb-3">
            {renderQualification(qualification, QUALIFICATION.PASSED_TEN)}
            {renderQualification(qualification, QUALIFICATION.PASSED_PLUS_TWO)}
            {renderQualification(
              qualification,
              QUALIFICATION.PASSED_PLUS_THREE
            )}
          </div>

          <Input name={"Other Qualification"} />

          <AddharInput
            name={"Aadhar Number"}
            type="tel"
            minLength={14}
            maxLength={14}
            required
          />

          <h5 className="text-primary mb-3 fw-bold">Documents</h5>

          <Input
            name={"Upload Aadhar Front"}
            type={"file"}
            accept="image/*"
            required
          />
          <Input
            name={"Upload Aadhar Back"}
            type={"file"}
            accept="image/*"
            required
          />
          <Input
            name={"Upload Passphoto"}
            type={"file"}
            accept="image/*"
            required
          />

          <h5 className="text-primary mb-3 fw-bold">Address</h5>
          <Input
            name={"Plot/Flat Number or Budling Name"}
            label={"Address Line 1"}
            required
          />
          <Input name={"Address Line 1"} label={"Address Line 2"} />
          <Input name={"City/Village"} required />
          <Input name={"Landmark"} />
          <Input name={"Dist"} required />
          <p className="mx-3 text-muted">State</p>
          <SelectInput
            name={"State"}
            options={stateOptions}
            defaultValue={"Odisha"}
          />
          <Input
            name={"Pincode"}
            type="tel"
            minLength={6}
            maxLength={6}
            required
          />
          {console.log("rrrrra", ranks)}
          <h5 className="text-primary mb-3 fw-bold">Rank & Sponsor</h5>
          <div className="d-flex my-2 flex-wrap flex-row">
            {ranks?.map((item) => renderRank(rank, item))}
          </div>

          <SelectInput
            name={"Sponsor’s Associate ID"}
            options={getSponsors?.data?.map((item) => ({
              label:
                item?.associate?.serialNo +
                " " +
                item?.associate?.name +
                " " +
                item?.designation?.name,
              value: item.associate.id,
            }))}
            className="d-none"
            value={id}
            disabled
          />
          <Input name={"Personal Student ID"} />

          {/* {
            postAssociateResponse.isLoading ?
              <button class={btnClassName} type="button" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Creating...
              </button>
              :
              <button type='submit' className={btnClassName}>Create</button>
          } */}

          <input type="hidden" name="paymentSkipped" value={paymentSkipped} />

          <button
            type="submit"
            className={btnClassName}
            onClick={() => setPaymentSkipped(false)}
          >
            Pay, ₹{packageData?.data?.packagePrice}
          </button>
          <button
            type="submit"
            className={"btn text-primary fw-bold fs-5 mx-auto ms-5 mt-1"}
            onClick={() => setPaymentSkipped(true)}
          >
            Skip Payment and Submit
          </button>

          <div className="py-5"></div>
        </form>
      </div>
    </>
  );
};

export default CreateAssociate;
