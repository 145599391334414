import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";


export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getLeads: builder.query({ query: () => `/lead`, keepUnusedDataFor: 0 }),
        postLead: builder.mutation({ query: (body) => ({ url: `/lead/create`, method: 'POST', body }) }),
        updateLeadStatus: builder.mutation({ query: (body) => ({ url: `/lead/updateStatus`, method: 'POST', body }) }),

       

    }),
})

export const {useGetLeadsQuery,usePostLeadMutation, useUpdateLeadStatusMutation} = extendedApiSlice
