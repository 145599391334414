import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";


export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getGateway: builder.query({ query: () => `/gateway`, keepUnusedDataFor: 0 }),
        postGateway: builder.mutation({ query: (body) => ({ url: `/gateway/create`, method: 'POST', body }) }),
        deleteGateway: builder.mutation({ query: (id) => ({ url: `/gateway/delete?id=${id}`, method: 'DELETE', }) }),
        updateGateway: builder.mutation({ query: ({id,body}) => ({ url: `/gateway/edit?id=${id}`, method: 'PUT', body }) }),



       

    }),
})

export const {useGetGatewayQuery,useDeleteGatewayMutation,usePostGatewayMutation,useUpdateGatewayMutation} = extendedApiSlice
