import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";


export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPackage: builder.query({ query: (id) => `/admin/getPackage?id=${id}`, keepUnusedDataFor: 0 }),

        getSiteInfo: builder.query({ query: (id) => `/admin/getSiteInfo?id=${id}`, keepUnusedDataFor: 0 }),
        

       

    }),
})

export const {useGetPackageQuery,useGetSiteInfoQuery} = extendedApiSlice
