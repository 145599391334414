import React, { useEffect, useState } from "react";
import { useGetPackageQuery } from "../../services/adminSlice";
import BachchaPicContainer from "../signIn/BachchaPicContainer";
import Loading from "../../components/ui/Loading";
import ApiErrorModal from "../../components/modals/ApiErrorModal";
import { useGetGatewayQuery } from "../../services/selectedGatewaySlice";

const Pay = ({ associate, resIsLoading, refetch }) => {
  const packageData = useGetPackageQuery(1);
  const [paying, setPaying] = useState(false);
  const gatewayData = useGetGatewayQuery();

  const handlePay = () => {
    setPaying(true);
    try {
      const url = `https://api-associate.sudikshya.co.in/api/ccavenue?id=${associate?.id}&name=${associate?.name}&amount=${packageData?.data?.packagePrice}`;

      if (window.cordova) {
        const ref = window.cordova.InAppBrowser.open(
          url,
          "_blank",
          "location=no,ignoresslerror=true"
        );
        ref.addEventListener("loaderror", function (event) {
          console.error("InAppBrowser load error: " + event.message);
        });
      } else window.open(url, "_self");
    } catch (error) {
      console.error("Error opening payment window:", error);
    }
  };

  const handlePayWithPhonePe = () => {
    setPaying(true);
    try {
      const url = `https://api-associate.sudikshya.co.in/phonepe/associate?associateId=${associate?.id}&amount=${packageData?.data?.packagePrice}`;

      if (window.cordova) {
        const ref = window.cordova.InAppBrowser.open(
          url,
          "_blank",
          "location=no,ignoresslerror=true"
        );
        ref.addEventListener("loaderror", function (event) {
          console.error("InAppBrowser load error: " + event.message);
        });
      } else window.open(url, "_self");
    } catch (error) {
      console.error("Error opening payment window:", error);
    }
  };

  useEffect(() => {
    let timer;
    if (paying) {
      timer = setInterval(() => {
        console.log(" refetchinggggg::::GG::G::G:::G");
        refetch();
      }, 5000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [paying]);

  if (packageData.isLoading || resIsLoading || gatewayData.isLoading)
    return <Loading />;
  if (packageData.isError) return <ApiErrorModal response={packageData} />;

  return (
    <BachchaPicContainer withoutPic>
      <style>
        {`
                    .bg-header-auth{
                      background:#70018b;
                      padding: 40px 0px !important;
                      border-radius: 0px 0px 20px 20px;
                    }
                    .btn.btn-primary {
                        padding: 10px;
                    }
                    
                    `}
      </style>
      <div className="d-flex flex-column align-items-center p-5 h-100">
        <div className="text-muted align-self-start fs-5 fw-bold mb-3">
          Payment summary{" "}
        </div>
        <h6 className="my-2 align-self-start mb-2">
          Payment for Associate - {associate?.name}
        </h6>

        <div className="d-flex bg-secondary bg-opacity-50 w-100 p-2 rounded-4">
          <div
            className="bg-white rounded-3"
            style={{ width: 50, height: 50 }}
          ></div>

          <div className="ps-3">
            <div className="fw-bold">{packageData?.data?.packageName}</div>
            <h4 className="text-primary fw-bold">
              ₹{packageData?.data?.packagePrice}
            </h4>
          </div>
        </div>

        {gatewayData.data[0].gateway == "ccavenue" ? (
          <>
            {paying ? (
              <button
                class="btn btn-primary btn-lg mt-auto rounded-4 w-100"
                type="button"
                disabled
              >
                <span
                  class="spinner-border spinner-border-sm"
                  aria-hidden="true"
                ></span>
                <span role="status"> Paying...</span>
              </button>
            ) : (
              <button
                className="btn btn-primary btn-lg mt-auto rounded-4 w-100"
                onClick={handlePay}
              >
                Pay Now
              </button>
            )}
          </>
        ) : (
          <>
            {paying ? (
              <button
                class="btn btn-primary btn-lg mt-auto rounded-4 w-100"
                type="button"
                disabled
              >
                <span
                  class="spinner-border spinner-border-sm"
                  aria-hidden="true"
                ></span>
                <span role="status"> Paying...</span>
              </button>
            ) : (
              <button
                className="btn btn-primary btn-lg mt-auto rounded-4 w-100"
                onClick={handlePayWithPhonePe}
              >
                Pay Now
              </button>
            )}
          </>
        )}
      </div>
    </BachchaPicContainer>
  );

  return (
    <div className="h-50 d-flex flex-column">
      <div className="text-center mt-5">
        <h3>Payment</h3>
      </div>
      <div className=" mt-2 text-center">
        {paying ? (
          <>
            <p>Fetching Payment...</p>
            {/* You can replace this with your spinner component */}
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </>
        ) : (
          <button className="btn btn-primary" onClick={handlePay}>
            Pay Now
          </button>
        )}
      </div>
    </div>
  );
};

export default Pay;
