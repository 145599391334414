import React from "react";
import Icons from "../ui/Icons";
import { Link, useLocation, useNavigation } from "react-router-dom";
import Logo from "../../assets/images/logo_sudikshya.png";
import { useGetSiteInfoQuery } from "../../services/adminSlice";
import Api from "../../constants/Api";
import { useGetUnReadNotificationsQuery } from "../../services/associateSlice";

const Headbar = ({ id }) => {
  const getinfo = useGetSiteInfoQuery(1);
  const getunRead = useGetUnReadNotificationsQuery();
  return (
    <div
      className="d-flex bg-primary p-3 justify-content-between align-items-center sticky-top"
      style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.1)" }}
    >
      <style>
        {`

a.notifbtn{
    background: #fff;
    padding: 10px;
    min-height: 50px;
    min-width: 50px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(20deg);
}
a.notifbtn svg path:first-child{
    fill: #F3AB49;
}
span.notificationdot {
    display: block;
    background: red;
    border-radius: 20px;
    position: absolute;
    top: 4px;
    left: -8px;
    padding: 2px 7px;
    color: #fff;
    font-size: 12px;
    transform: rotate(-20deg);
}
            `}
      </style>

      <Link to={"/"}>
        <img
          src={Api.BASE_URL + getinfo?.data?.userLogo}
          alt="Sudikshya "
          className="text-white"
          height={50}
        />
      </Link>
      <Link to={`/notifications/${id}`} className="notifbtn">
        {getunRead?.data?.filter((item) => item.associateId === id).length >
          0 && (
          <span className="notificationdot">
            {getunRead?.data?.filter((item) => item.associateId === id).length}
          </span>
        )}
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="icon "
        >
          <path
            d="M16.4062 8.49625V7.87938C16.4062 4.494 13.7602 1.75 10.5 1.75C7.23974 1.75 4.59374 4.494 4.59374 7.87938V8.49625C4.5948 9.23261 4.38466 9.95383 3.98824 10.5744L3.01874 12.0838C2.13412 13.4619 2.80962 15.3353 4.34874 15.771C8.37034 16.9111 12.6297 16.9111 16.6512 15.771C18.1904 15.3353 18.8659 13.4619 17.9812 12.0846L17.0117 10.5753C16.615 9.95479 16.4046 9.23357 16.4054 8.49713L16.4062 8.49625Z"
            stroke="#F3AB49"
            stroke-width="1.5"
          ></path>
          <path
            d="M6.5625 16.625C7.13562 18.1545 8.68175 19.25 10.5 19.25C12.3183 19.25 13.8644 18.1545 14.4375 16.625"
            stroke="#F3AB49"
            stroke-width="1.5"
            stroke-linecap="round"
          ></path>
        </svg>
      </Link>
    </div>
  );
};

export default Headbar;
