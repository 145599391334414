import React, { useEffect } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "../ui/Loading";
import ApiErrorModal from "../modals/ApiErrorModal";
import SignUp from "../../pages/home/SignUp";
import { useGetAssociateByTokenQuery } from "../../services/associateSlice";
import Pending from "../../pages/home/Pending";
import Pay from "../../pages/home/Pay";
import Rejected from "../../pages/home/Rejected";
import InActive from "../../pages/home/InActive";
import Icons from "../ui/Icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Layout = () => {
  const token = useSelector((state) => state.auth.authToken);

  const res = useGetAssociateByTokenQuery(token, { pollingInterval: 5000 });
  const { pathname } = useLocation();

  useEffect(() => {
    res.refetch();
  }, []);

  useEffect(() => {
    const classList = document.body.classList;
    if (classList.contains("modal-open")) classList.remove("modal-open");

    const backdrops = document.getElementsByClassName("modal-backdrop");
    for (const backdrop of backdrops) backdrop.remove();
  }, [pathname]);

  if (res.isLoading) return <Loading />;
  if (res.isError) return <ApiErrorModal response={res} />;
  if (res?.data?.associate?.status == "SIGN_UP_PENDING")
    return (
      <SignUp
        refetch={res.refetch}
        mobileNumber={res?.data?.associate?.mobileNumber}
      />
    );
  if (res?.data?.associate?.paid == "0")
    return (
      <Pay
        resIsLoading={res.isLoading}
        associate={res?.data?.associate}
        refetch={res.refetch}
      />
    );
  if (res?.data?.associate?.isActive != "1")
    return <InActive id={res?.data?.associate?.id} res={res} />;
  if (res?.data?.associate?.status == "Rejected")
    return <Rejected id={res?.data?.associate?.id} res={res} />;
  if (
    res?.data?.associate?.status == "Pending" ||
    res?.data?.associate?.status == "ReApplied"
  )
    return <Pending />;

  let render = !(
    pathname.startsWith("/associate/create") ||
    pathname.startsWith("/student/create") ||
    pathname.startsWith("/leads/create") ||
    pathname.startsWith("/notifications")
  );

  return (
    <div className="h-100 w-100 d-flex flex-column">
      <style>
        {`

        body{
          font-size:15px;
        }
          
        .bg-bottombar {
            background: #70018b;
            margin-left: 10px;
            margin-right: 10px;
            margin-top: 5px;
            margin-bottom: 5px;
            border-radius: 50px;

        }
        .bg-bottombar a.nav-link {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            padding: 6px;
        }
        .bg-bottombar .nav-link p {
            font-size: 12px;
            margin-top: 5px;
        }
        
        `}
      </style>

      <div className="overflow-hidden flex-grow-1 d-flex flex-column">
        <main className="d-flex flex-column overflow-auto  h-100 w-100  style flex-grow-1">
          <Outlet />
        </main>
        {render && (
          <div className="bg-bottombar">
            <div className="d-flex text-white display-1 text-center p-2 m-0 justify-content-center align-items-center">
              <NavLink to={"/"} className="nav-link">
                {Icons.home()} <p>Home</p>
              </NavLink>
              <NavLink to={"/settings"} className="nav-link">
                {Icons.settings()}
                <p>Profile</p>
              </NavLink>
              <NavLink to={"/contactus"} className="nav-link">
                {Icons.team()} <p>Contact Us</p>
              </NavLink>
            </div>
          </div>
        )}

        <ToastContainer />
      </div>
    </div>
  );
};

export default Layout;
