import { MdNotificationsNone } from "react-icons/md";
import {
  RiTeamFill,
  RiLogoutCircleRLine,
  RiAddCircleFill,
  RiAddFill,
} from "react-icons/ri";
import { IoDocumentText, IoSettings } from "react-icons/io5";
import { IoChevronBack } from "react-icons/io5";
import { PiStudentFill, PiNotebookFill } from "react-icons/pi";
import { IoHome } from "react-icons/io5";

const Icons = {
  team: (className, style) => (
    <svg
      width="30"
      height="28"
      viewBox="0 0 30 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon ${className || ""}`}
      style={{ color: "inherit", ...style }}
    >
      <path
        d="M11.024 18.2866C17.002 18.2866 22.048 19.2367 22.048 22.9105C22.048 26.5815 16.9697 27.5 11.024 27.5L10.6179 27.4985C4.81924 27.4554 0 26.4664 0 22.8761C0 19.2051 5.07829 18.2866 11.024 18.2866ZM20.3545 16.0748C22.628 16.0317 25.0732 16.3378 25.9759 16.5534C27.8898 16.92 29.1476 17.666 29.6687 18.754C30.1104 19.6452 30.1104 20.6801 29.6687 21.5712C28.8717 23.2558 26.3003 23.7977 25.3007 23.9371C25.0938 23.9658 24.9279 23.7934 24.95 23.5893C25.4607 18.9208 21.3996 16.7072 20.3487 16.1984C20.3046 16.174 20.2944 16.1395 20.2988 16.1165C20.3017 16.1021 20.3208 16.0791 20.3545 16.0748ZM11.024 0.5C15.0734 0.5 18.3185 3.67798 18.3185 7.64362C18.3185 11.6093 15.0734 14.7872 11.024 14.7872C6.97604 14.7872 3.72946 11.6093 3.72946 7.64362C3.72946 3.67798 6.97604 0.5 11.024 0.5ZM20.7508 1.69314C24.6623 1.69314 27.7327 5.29514 26.6863 9.30677C25.9803 12.0104 23.425 13.8057 20.5776 13.7324C20.2914 13.7266 20.0111 13.6993 19.7381 13.6547C19.5414 13.6203 19.4416 13.4032 19.5546 13.2422C20.6408 11.677 21.2601 9.7926 21.2601 7.77312C21.2601 5.6631 20.585 3.69824 19.4093 2.0841C19.3712 2.03379 19.3433 1.95761 19.3815 1.89868C19.4108 1.85269 19.4695 1.82682 19.5238 1.81532C19.9201 1.73626 20.3267 1.69314 20.7508 1.69314Z"
        fill="currentColor"
      />
    </svg>
  ),

  student: (className, style) => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon ${className || ""}`}
      style={{ color: "inherit", ...style }}
    >
      <path
        d="M31.7006 17.534C31.8141 17.5978 31.8368 17.733 31.8413 17.7846L31.8424 17.8051L31.7164 25.9393C31.7164 29.184 29.2023 31.8299 26.0367 31.9921L25.7284 32H6.75588C3.55013 32 0.935927 29.4554 0.77567 26.2513L0.767884 25.9393L0.657579 17.8051C0.657579 17.8051 0.657579 17.6137 0.7994 17.534C0.925463 17.4543 1.08304 17.5499 1.0988 17.5659C4.28189 19.7031 8.11106 21.1226 12.1293 21.7127C12.6966 21.7925 13.2639 21.4256 13.4215 20.8514C13.7465 19.6253 14.7501 18.7917 15.9779 18.6923L16.2264 18.6823H16.2736C17.6131 18.6823 18.7319 19.5436 19.0785 20.8514C19.2361 21.4256 19.8034 21.7925 20.3707 21.7127C24.3889 21.1226 28.2181 19.7031 31.4012 17.5659C31.4012 17.5659 31.4327 17.5499 31.4642 17.534C31.5273 17.5021 31.6218 17.4861 31.7006 17.534ZM16.2421 20.9631C15.6499 20.9631 15.1503 21.4188 15.0712 21.9984L15.0603 22.1593V24.2167C15.0603 24.8866 15.596 25.4129 16.2421 25.4129C16.8488 25.4129 17.3363 24.9707 17.4133 24.381L17.424 24.2167V22.1593C17.424 21.5054 16.904 20.9631 16.2421 20.9631ZM18.2749 0.5C20.6386 0.5 22.5768 2.30227 22.8762 4.61492H25.9962C29.3054 4.61492 32 7.34224 32 10.6916C32 10.6916 31.9055 12.1095 31.8739 14.084C31.8708 14.2403 31.7951 14.3934 31.6707 14.4859C30.9127 15.0458 30.2194 15.5083 30.1563 15.5402C27.5405 17.2946 24.5008 18.5291 21.2626 19.1431C21.0514 19.1846 20.8434 19.0745 20.7363 18.8863C19.8286 17.3137 18.1331 16.2898 16.2421 16.2898C14.3638 16.2898 12.6525 17.3026 11.718 18.8768C11.6093 19.0618 11.4045 19.1686 11.1949 19.1288C7.98342 18.5131 4.94372 17.2802 2.34367 15.5561L0.830915 14.5035C0.704852 14.4237 0.626063 14.2802 0.626063 14.1207C0.578789 13.3073 0.5 10.6916 0.5 10.6916C0.5 7.34224 3.1946 4.61492 6.50375 4.61492H9.60805C9.90745 2.30227 11.8457 0.5 14.2094 0.5H18.2749ZM18.2749 2.89239H14.2094C13.1536 2.89239 12.2554 3.62606 12.0033 4.61492H20.481C20.2289 3.62606 19.3307 2.89239 18.2749 2.89239Z"
        fill="currentColor"
      />
    </svg>
  ),
  lead: (className, style) => (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon ${className || ""}`}
      style={{ color: "inherit", ...style }}
    >
      <path
        d="M8.22992 15.3983C10.0512 15.3983 11.5108 16.8721 11.5108 18.7063V23.1096C11.5108 24.9308 10.0512 26.4163 8.22992 26.4163H3.86409C2.05575 26.4163 0.583252 24.9308 0.583252 23.1096V18.7063C0.583252 16.8721 2.05575 15.3983 3.86409 15.3983H8.22992ZM23.1359 15.3983C24.9442 15.3983 26.4167 16.8721 26.4167 18.7063V23.1096C26.4167 24.9308 24.9442 26.4163 23.1359 26.4163H18.77C16.9488 26.4163 15.4892 24.9308 15.4892 23.1096V18.7063C15.4892 16.8721 16.9488 15.3983 18.77 15.3983H23.1359ZM8.22992 0.583313C10.0512 0.583313 11.5108 2.06873 11.5108 3.89127V8.29456C11.5108 10.1287 10.0512 11.6012 8.22992 11.6012H3.86409C2.05575 11.6012 0.583252 10.1287 0.583252 8.29456V3.89127C0.583252 2.06873 2.05575 0.583313 3.86409 0.583313H8.22992ZM23.1359 0.583313C24.9442 0.583313 26.4167 2.06873 26.4167 3.89127V8.29456C26.4167 10.1287 24.9442 11.6012 23.1359 11.6012H18.77C16.9488 11.6012 15.4892 10.1287 15.4892 8.29456V3.89127C15.4892 2.06873 16.9488 0.583313 18.77 0.583313H23.1359Z"
        fill="currentColor"
      />
    </svg>
  ),
  doc: (className, style) => (
    <svg
      width="27"
      height="31"
      viewBox="0 0 27 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon ${className || ""}`}
      style={{ color: "inherit", ...style }}
    >
      <path
        d="M15.4308 0.166687C15.8277 0.166687 16.133 0.490437 16.133 0.875854V5.84002C16.133 8.66127 18.4378 10.9738 21.2311 10.9892C22.3912 10.9892 23.3071 11.0046 24.0092 11.0046L24.2693 11.0035C24.7385 10.9999 25.3702 10.9892 25.9172 10.9892C26.2988 10.9892 26.6041 11.2975 26.6041 11.6829V24.0779C26.6041 27.9013 23.536 31 19.7505 31H7.60037C3.63173 31 0.395752 27.7471 0.395752 23.7388V7.1196C0.395752 3.29627 3.47908 0.166687 7.27983 0.166687H15.4308ZM17.0641 20.0542H8.76044C8.13461 20.0542 7.61564 20.5629 7.61564 21.195C7.61564 21.8271 8.13461 22.3513 8.76044 22.3513H17.0641C17.6899 22.3513 18.2089 21.8271 18.2089 21.195C18.2089 20.5629 17.6899 20.0542 17.0641 20.0542ZM13.9197 12.3459H8.76044C8.13461 12.3459 7.61564 12.87 7.61564 13.5021C7.61564 14.1342 8.13461 14.6429 8.76044 14.6429H13.9197C14.5455 14.6429 15.0645 14.1342 15.0645 13.5021C15.0645 12.87 14.5455 12.3459 13.9197 12.3459ZM18.3572 1.56344C18.3572 0.898979 19.1555 0.569062 19.6119 1.04852C21.262 2.78135 24.1454 5.81073 25.7572 7.50348C26.2029 7.9706 25.8763 8.74606 25.2337 8.7476C23.979 8.75223 22.4999 8.7476 21.436 8.73681C19.7478 8.73681 18.3572 7.33235 18.3572 5.62727V1.56344Z"
        fill="currentColor"
      />
    </svg>
  ),
  back: (className, style) => (
    <IoChevronBack className={className} style={style} />
  ),
  settings: (className, style) => (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon ${className || ""}`}
      style={{ color: "inherit", ...style }}
    >
      <path
        d="M13.4964 17.0714C8.64426 17.0714 4.49976 17.8364 4.49976 20.8964C4.49976 23.9575 8.61838 24.7495 13.4964 24.7495C18.3485 24.7495 22.493 23.9857 22.493 20.9245C22.493 17.8634 18.3755 17.0714 13.4964 17.0714Z"
        fill="currentColor"
      />
      <path
        d="M13.4965 14.1566C16.8017 14.1566 19.45 11.5073 19.45 8.20313C19.45 4.89901 16.8017 2.24963 13.4965 2.24963C10.1923 2.24963 7.54297 4.89901 7.54297 8.20313C7.54297 11.5073 10.1923 14.1566 13.4965 14.1566Z"
        fill="currentColor"
      />
    </svg>
  ),
  noti: (className, style) => (
    <MdNotificationsNone className={className} style={style} />
  ),
  signOut: (className, style) => (
    <RiLogoutCircleRLine className={className} style={style} />
  ),
  create: (className, style) => (
    <RiAddCircleFill className={className} style={style} />
  ),
  home: (className, style) => (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon ${className || ""}`}
      style={{ color: "inherit", ...style }}
    >
      <path
        d="M10.2867 23.3799V19.9297C10.2867 19.0554 10.9976 18.345 11.8782 18.3395H15.1117C15.9962 18.3395 16.7133 19.0515 16.7133 19.9297V23.3699C16.7133 24.1282 17.3295 24.7445 18.0933 24.75H20.2993C21.3296 24.7526 22.3186 24.3481 23.0481 23.6258C23.7775 22.9034 24.1875 21.9226 24.1875 20.8997V11.0991C24.1875 10.2728 23.8186 9.48905 23.1802 8.95894L15.6859 3.00855C14.3758 1.96776 12.5048 2.00138 11.2336 3.08856L3.90039 8.95894C3.23183 9.47342 2.83225 10.2595 2.8125 11.0991V20.8897C2.8125 23.0217 4.5533 24.75 6.7007 24.75H8.85633C9.22407 24.7526 9.57767 24.6095 9.83866 24.3522C10.0996 24.095 10.2464 23.745 10.2464 23.3799H10.2867Z"
        fill="currentColor"
      />
    </svg>
  ),
  plus: (className, style) => <RiAddFill className={className} style={style} />,
  notification: (className, style) => (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon ${className || ""}`}
      style={{ color: "inherit", ...style }}
    >
      <path
        d="M16.4062 8.49625V7.87938C16.4062 4.494 13.7602 1.75 10.5 1.75C7.23974 1.75 4.59374 4.494 4.59374 7.87938V8.49625C4.5948 9.23261 4.38466 9.95383 3.98824 10.5744L3.01874 12.0838C2.13412 13.4619 2.80962 15.3353 4.34874 15.771C8.37034 16.9111 12.6297 16.9111 16.6512 15.771C18.1904 15.3353 18.8659 13.4619 17.9812 12.0846L17.0117 10.5753C16.615 9.95479 16.4046 9.23357 16.4054 8.49713L16.4062 8.49625Z"
        stroke="#F3AB49"
        stroke-width="1.5"
      />
      <path
        d="M6.5625 16.625C7.13562 18.1545 8.68175 19.25 10.5 19.25C12.3183 19.25 13.8644 18.1545 14.4375 16.625"
        stroke="#F3AB49"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  ),
  view: (className, style) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon ${className || ""}`}
      style={{ color: "inherit", ...style }}
    >
      <path
        d="M2 12C2 12 5 5 12 5C19 5 22 12 22 12C22 12 19 19 12 19C5 19 2 12 2 12Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),

  download: (className, style) => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon ${className || ""}`}
      style={{ color: "inherit", ...style }}
    >
      <path
        d="M3.4375 17.5C2.85734 17.5 2.30094 17.2695 1.8907 16.8593C1.48047 16.4491 1.25 15.8927 1.25 15.3125V12.1875C1.25 11.9389 1.34877 11.7004 1.52459 11.5246C1.7004 11.3488 1.93886 11.25 2.1875 11.25C2.43614 11.25 2.6746 11.3488 2.85041 11.5246C3.02623 11.7004 3.125 11.9389 3.125 12.1875V15.3125C3.125 15.485 3.265 15.625 3.4375 15.625H16.5625C16.6454 15.625 16.7249 15.5921 16.7835 15.5335C16.8421 15.4749 16.875 15.3954 16.875 15.3125V12.1875C16.875 11.9389 16.9738 11.7004 17.1496 11.5246C17.3254 11.3488 17.5639 11.25 17.8125 11.25C18.0611 11.25 18.2996 11.3488 18.4754 11.5246C18.6512 11.7004 18.75 11.9389 18.75 12.1875V15.3125C18.75 15.8927 18.5195 16.4491 18.1093 16.8593C17.6991 17.2695 17.1427 17.5 16.5625 17.5H3.4375Z"
        fill="white"
      />
      <path
        d="M9.0625 9.61125V2.5C9.0625 2.25136 9.16127 2.0129 9.33708 1.83709C9.5129 1.66127 9.75136 1.5625 10 1.5625C10.2486 1.5625 10.4871 1.66127 10.6629 1.83709C10.8387 2.0129 10.9375 2.25136 10.9375 2.5V9.61125L13.4 7.15C13.487 7.063 13.5903 6.99399 13.704 6.9469C13.8176 6.89982 13.9395 6.87558 14.0625 6.87558C14.1855 6.87558 14.3074 6.89982 14.421 6.9469C14.5347 6.99399 14.638 7.063 14.725 7.15C14.812 7.237 14.881 7.34029 14.9281 7.45396C14.9752 7.56763 14.9994 7.68946 14.9994 7.8125C14.9994 7.93554 14.9752 8.05737 14.9281 8.17104C14.881 8.28471 14.812 8.388 14.725 8.475L10.6625 12.5375C10.5755 12.6246 10.4723 12.6937 10.3586 12.7408C10.2449 12.7879 10.1231 12.8122 10 12.8122C9.87693 12.8122 9.75508 12.7879 9.6414 12.7408C9.52772 12.6937 9.42445 12.6246 9.3375 12.5375L5.275 8.475C5.188 8.388 5.11898 8.28471 5.0719 8.17104C5.02481 8.05737 5.00058 7.93554 5.00058 7.8125C5.00058 7.68946 5.02481 7.56763 5.0719 7.45396C5.11898 7.34029 5.188 7.237 5.275 7.15C5.362 7.063 5.46528 6.99399 5.57895 6.9469C5.69263 6.89982 5.81446 6.87558 5.9375 6.87558C6.06053 6.87558 6.18237 6.89982 6.29604 6.9469C6.40971 6.99399 6.513 7.063 6.6 7.15L9.0625 9.61125Z"
        fill="white"
      />
    </svg>
  ),
};

export default Icons;
