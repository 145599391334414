import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";


export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCategories: builder.query({ query: () => `/category`, keepUnusedDataFor: 0 }),
        postCategory: builder.mutation({ query: (body) => ({ url: `/category/create`, method: 'POST', body }) }),

       

    }),
})

export const {useGetCategoriesQuery, usePostCategoryMutation} = extendedApiSlice
