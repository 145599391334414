

import React, { useState } from 'react';
import { useCheckOtpMutation, useSignOutMutation, useVerfiyPasswordMutation } from '../../services/associateSlice';
import Icons from './Icons';
import ConfirmationModal from '../modals/ConfirmationModal';
import { setAuthToken } from '../../redux/authSlice';
import { useDispatch, useSelector } from 'react-redux';
// Assuming ConfirmationModal is in the same directory

const SignOutInactive = () => {
    const [showModal, setShowModal] = useState(false);

    const [signOutApi] = useSignOutMutation();
    const [verifyPassword, verifyPasswordResponse] = useVerfiyPasswordMutation({ fixedCacheKey: 'signInUser' });
    const [checkOtp, checkOtpResponse] = useCheckOtpMutation({ fixedCacheKey: 'signInUser' });
    const dispatch = useDispatch()

    // const handleSignOut = (event) => {
    //     event.preventDefault();
    //     setShowModal(true);
    // }

    const handleConfirmSignOut = () => {
        verifyPasswordResponse.reset();
        checkOtpResponse.reset();
        signOutApi();

        dispatch(setAuthToken(null))

    }



    return (
        <>

            <button type="button" id="successModalButton" className='btn btn-primary mt-4 rounded-5 px-5 py-2 fs-5' data-bs-toggle="modal" data-bs-target="#confirmationModal">
                SIGN OUT 
            </button>


            {/* <div className='row col-11 border border-black py-0 px-3 m-3 rounded-2 fw-bold btn btn-primary'  data-bs-toggle="modal" data-bs-target={"#" + ConfirmationModal}>
                SIGN OUT {Icons.signOut("text-white", { width: 60, height: 60 })}
            </div>
            */}
            <ConfirmationModal
                onConfirm={handleConfirmSignOut}
            />

        </>
    )
}

export default SignOutInactive;
