import React, { useEffect } from 'react'

const SuccessModal = ({ label, message }) => {

    useEffect(() => {
        document.getElementById("successModalButton")?.click()
    }, [])

    return (
        <>
            <button type="button" id="successModalButton" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#successModal">
                Launch demo modal
            </button>

            <div className="modal fade" id="successModal" tabindex="-1" aria-labelledby="successModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="successModalLabel">{label ? label : "Success"}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">{message ? message : "Success"}</div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SuccessModal