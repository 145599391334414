import React from "react";
import Input from "../../components/form/Input";
import SelectInput from "../../components/form/SelectInput";
import { useGetAssociateByTokenQuery, useGetAssociatesQuery } from "../../services/associateSlice";
import { usePostProductMutation } from "../../services/productSlice";
import {
  useGetLeadsQuery,
  usePostLeadMutation,
} from "../../services/leadSlice";
import SuccessModal from "../../components/modals/SuccessModal";
import ApiErrorModal from "../../components/modals/ApiErrorModal";
import { Link, useNavigate, useParams } from "react-router-dom";
import Icons from "../../components/ui/Icons";
import { convertToFiveDigits } from "../../utils/ConvertToFive";
import { useSelector } from "react-redux";

const CreateLead = () => {

  const { id } = useParams();
  const token = useSelector((state) => state.auth.authToken);
  const associateRes = useGetAssociateByTokenQuery(token);
  const [postLead, postLeadResponse] = usePostLeadMutation();
  const response = useGetLeadsQuery();

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    if (form.checkValidity()) {
      const body = {
        name: e.target["Name"].value,
        associateId: id,
        address: e.target["Address"].value,
        mobileNumber: e.target["Mobile Number"].value,
      };

      postLead(body).then(() => {
        form.classList.remove("was-validated");
        form.reset();
        response.refetch();
        navigate("/leads");
      });
    } else {
      form.classList.add("was-validated");
    }
  };

  if (associateRes.isError) return <ApiErrorModal response={associateRes} />;

  return (
    <>
      <div className="bg-primary p-3 min-4">
        <div className="d-flex w-100 align-items-center px-2">
          <Link to={-1}>
            {Icons.back("text-white", { width: 32, height: 32 })}
          </Link>

          <div className="flex-grow-1 ps-3 text-light">
            {/* <div>{currentRes.data.associate.name}</div> */}
            <h3 className="fw-bold text-primary text-white">Create Lead</h3>
          </div>
        </div>
      </div>

      <div className="container p-3">
        {postLeadResponse.isSuccess && (
          <SuccessModal message={"Lead has been created."} />
        )}
        {postLeadResponse.isError && (
          <ApiErrorModal response={postLeadResponse} />
        )}

        <h4 className="fw-bold mb-3">Create Lead</h4>

        <form onSubmit={handleSubmit} noValidate>
          <div className="row row-cols-12 ">
            <Input containerClass="" name={"Name"} required />

            <Input
              containerClass=""
              name={"Associate Id"}
              value={`S3A${convertToFiveDigits(associateRes.data.associate?.serialNo)}`}
              disabled
            />

            <Input containerClass="" name={"Address"} required />
            <Input
              containerClass=""
              name={"Mobile Number"}
              type={"tel"}
              minLength={10}
              maxLength={10}
              required
            />
          </div>
          <button
            className="btn btn-primary rounded-4 btn-lg py-3 text-white mt-3 w-100"
            type="submit"
          >
            Create Lead
          </button>
        </form>
      </div>
    </>
  );
};

export default CreateLead;
