import React, { useRef } from 'react';
import Icons from '../../components/ui/Icons';
import Api from '../../constants/Api';
import ReactToPrint from 'react-to-print';
import logo from "../../assets/images/logo.png";
import { convertToFiveDigits } from '../../utils/ConvertToFive';

const OfferLetterModal = ({ item }) => {
    console.log("-----------item", item);
    const modalId = "offerLetterDownloadModal" + item?.associate?.id;
    const modalBodyRef = useRef();

    const ModalBody = () => (
        <div className="modal-body  p-0" id={modalId + "ModalBody"} ref={modalBodyRef} style={{ zoom: "55%" }}>
            {/* Your existing modal body content */}
            <div className='w-100 bg-primary mb-3' style={{ height: 10 }}>
                <div className="bg-warning h-100 w-50 mx-auto"></div>
            </div>

            {/* <div className="d-flex justify-content-between mb-4 px-3">
                <div>Ref. ID: {item?.sponsor?.id}</div>
                <div>Date of Joiningf: {item.associate.createdAt}</div>
            </div> */}



            <div className="d-flex px-3 mb-5">
                <div className=''>
                    {/* Create offer letter template here */}

                    {/* <div className='float-start'>
                    <img src={logo} alt="" className='w-25 ' />
                      <p>  Ref No -</p>

                    </div>
                    
                    <div className='float-end'>
                    <img src={logo} alt="" className='w-25 ' />
                      <p>  Ref No -</p>
                    </div> */}

                    <div className='d-flex  justify-content-between'>

                        <div className='w-50'>
                            <img src={logo} alt="" style={{ width: "75%" }} />
                            <p className='mt-4'> Ref Id - {item.associate.refId} </p>
                        </div>

                        <div className='w-50 '   >
                            <div className='text-end text-primary'>
                                <p>SUDIKSHYA LEARNING PVT LTD</p>
                                <p>CIN : U80904OR2022PTC038955</p>
                            </div>
                            <p className='text-end mt-2'> Issue Date-  {item.associate.createdAt} </p>

                        </div>

                    </div>

                    <h4 className="mb-4 mt-4 text-center">Associate Offer Letter</h4>

                    <p>To</p>
                    <p>{item?.associate?.name},</p>
                    <p>C/o: {item?.associate?.fatherName},</p>
                    <p>Associate ID: {`S3A${convertToFiveDigits(item.associate.serialNo)}`},</p>

                    <p>Sub- You engaged as {item?.designation?.name} in Sudikshya,</p>
                    <p>Sir/Madam,</p>

                    <p>You are engaged as {item?.designation?.name} in SUDIKSHYA for the smooth teaching in SUDIKSHYA Apps of the stufents from nursery to 10th level by the following Systems.</p>

                    <ol>
                        <li>You have to create minimum five number of Survey Manager in your respective areas.</li>
                        <li>Your all Survey Managers have to create minimum 5 numbers of Survey Executive in their respective areas, where they can provide services to their team ans so on to their junior staff.</li>
                        <li>You have to take training for 90 days In the training period you can avail your incentive according to score.</li>

                        <li>The Sl No. 3 will continue up to 2 months including the 1st month.The monthly remuneration will continue rest of the year. You can avail the deductive incentive from your team when new students joining in your team.</li>

                        <li>Your joining order will ve dispatched after your training period.</li>

                        <li>Your monthly remuneration will be activated as Rs 25000/- (Twenty-five Thousand) only after your joining order, But incentives are provided when new students joinined in your team directly or indirectly.</li>

                        <li>Your all the staff have to provide best services to the students of their respective areas</li>

                        <li>If any staff of your team will procide extra student login other than the Sl No 4, then you and your respective staff will avail their extra incentives mention in the sl No 9</li>

                        <li>The duty time of you and your staff should maintain minium 8 to 10 hours per day.</li>

                        <li>The duty time is selected by you according to your selective times of your selective times of your selected aread</li>

                        <li>You may be disengaged if you are not properly working by the guidelines of the institute in the training period or you may be worked as incentive systems if you are not passed in the training period</li>

                        <p>N.B - Plans & Procefures may be changes without prior notice</p>




                    </ol>


                    <p className='mb-3'>Much obliged to you</p>
                    <p>CEO-Sudikshya</p>
                </div>
            </div>



        </div>
    );





    return (
        <>



            <div className=' rounded p-2 w-100 d-flex flex-column align-items-center ' style={{ backgroundColor: '#D9D9D9' }} data-bs-toggle="modal" data-bs-target={"#" + modalId}>

                <img src="https://i.imgur.com/MI4Rgha.png" alt="" style={{ width: '100%' }} />
                <p className='fw-bold fs-6 my-1'>Offer Letter</p>
            </div>

            <div className="modal fade   " id={modalId} tabIndex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true" >
                <div className="modal-dialog modal-dialog-scrollable  modal-dialog-centered  modal-lg">
                    <div className="modal-content rounded-3 overflow-hidden " >
                        {/* <div className="modal-header">
                            <h1 className="modal-title fs-5" id={modalId + "Label"}>{item.associate.name}'s Id Card</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div> */}
                        <ModalBody />
                    </div>
                </div>
            </div>
        </>
    );
};

export default OfferLetterModal;
