import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useGetStudentByAssociateQuery } from "../../services/studentSlice";
import Loading from "../../components/ui/Loading";
import userImg from "../../assets/images/user-default.png";
import {
  useGetAssociateByTokenQuery,
  useGetAssociateIdQuery,
} from "../../services/associateSlice";
import ApiErrorModal from "../../components/modals/ApiErrorModal";
import Icons from "../../components/ui/Icons";
import { useSelector } from "react-redux";
import { convertToFiveDigits } from "../../utils/ConvertToFive";
import SelectInput from "../../components/form/SelectInput";
import InputSearch from "../../components/form/InputSearch";

const Students = () => {
  const { id } = useParams();
  const token = useSelector((state) => state.auth.authToken);

  const currentRes = useGetAssociateIdQuery(id);
  const response = useGetStudentByAssociateQuery(id);

  const [searchValue, setSearchyValue] = useState("");
  const [selectValue, setSelectValue] = useState("Active");

  const res = useGetAssociateByTokenQuery(token);

  if (response.isLoading || currentRes.isLoading || res.isLoading)
    return <Loading />;
  if (response.isError) return <ApiErrorModal response={response} />;
  if (res.isError) return <ApiErrorModal response={res} />;
  if (currentRes.isError) return <ApiErrorModal response={currentRes} />;

  const renderItem = (item) => (
    <Link
      to={`../../students/detail/${item?.id}`}
      className="d-flex w-100 align-items-center px-3 py-4 border-bottom border-2 text-decoration-none text-dark"
    >
      <div
        style={{ height: 50, width: 50 }}
        className="bg-danger rounded-circle"
      >
        <img src={userImg} alt="" />
      </div>
      <div className="flex-grow-1 ps-3 fw-bold">
        <div>
          Student ID -{" "}
          <span className="text-warning">{`S2S${convertToFiveDigits(
            item?.serialNo
          )}`}</span>
        </div>
        <h3 className="fw-bold text-primary">{item.studentName}</h3>
      </div>
      {/* <div className="fw-bold me-3">
        {item.isActive == "1" ? "Active" : "InActive"}
      </div> */}
    </Link>
  );

  return (
    <>
      <div className="bg-primary p-3 min-4">
        <div className="d-flex w-100 align-items-center px-2">
          <Link to={-1}>
            {Icons.back("text-white", { width: 32, height: 32 })}
          </Link>

          <div className="flex-grow-1 ps-3 text-light">
            <div>{currentRes.data.associate.name}</div>
            <h3 className="fw-bold text-primary text-white">Students</h3>
          </div>

          <Link to={"/student/create/" + id}>
            {Icons.create("text-white", { width: 40, height: 40 })}
          </Link>
        </div>
      </div>

      <div className="row row-cols-2 row-cols-lg-3 g-3 mx-2 my-3">
        <div className="col d-none">
          <SelectInput
            name={"Select Status"}
            noLabel
            options={[
              { value: "", label: "Select" },
              { value: "Active", label: "Active" },
              { value: "InActive", label: "InActive" },
              { value: "Rejected", label: "Rejected" },
            ]}
            value={selectValue}
            onChange={(e) => setSelectValue(e.target.value)}
            containerClass={""}
            required
          />
        </div>

        <div className="col-12">
          <InputSearch
            name={"Search Student"}
            containerClass={""}
            value={searchValue}
            onChange={(e) => setSearchyValue(e.target.value)}
          />
        </div>
      </div>

      {/* <div className="text-center my-2">
        No. of Students:{" "}
        {response.data?.filter((item) => item.status == "Approved").length}
      </div> */}

      <div className="overflow-auto flex-grow-1 w-100">
        {response?.data
          ?.filter((item) => item.status == "Approved")
          .filter((item) => {
            if (searchValue?.length === 0 && selectValue?.length === 0)
              return true;

            const isSearchMatch =
              searchValue?.length > 0 &&
              (item?.studentName
                .toLowerCase()
                .includes(searchValue.toLowerCase()) ||
                `S2S${convertToFiveDigits(item?.id)}`
                  .toLowerCase()
                  .includes(searchValue.toLowerCase()));

            let isProductMatch = selectValue?.length > 0;

            if (isProductMatch) {
              switch (selectValue) {
                case "Active":
                  isProductMatch = item.isActive == "1";
                  break;
                case "InActive":
                  isProductMatch = item.isActive == "0";
                  break;
                default:
                  isProductMatch = true;
              }
            }

            if (searchValue?.length === 0) return isProductMatch;
            if (selectValue?.length === 0) return isSearchMatch;

            return isProductMatch && isSearchMatch;
          })
          ?.map(renderItem)}
      </div>
    </>
  );
};

export default Students;
