import React, { useEffect, useState } from 'react';
import BachchaPicContainer from '../signIn/BachchaPicContainer';
import Loading from '../../components/ui/Loading';
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import { useGetStudentQuery, useGetStudentsQuery, usePostStudentMutation } from '../../services/studentSlice';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetGatewayQuery } from '../../services/selectedGatewaySlice';

const StudentPay = () => {

  const { id } = useParams();
  const [postStudent, postStudentResponse] = usePostStudentMutation()

  const [paying, setPaying] = useState(false);
  const navigate = useNavigate();
  const studentData = useGetStudentQuery(id);
  const response = useGetStudentsQuery()
  const gatewayData = useGetGatewayQuery()




  const handlePay = () => {
    setPaying(true);
    try {
      const url = `https://api-associate.sudikshya.co.in/api/studentCcavenue?id=${studentData?.data?.student?.id}&name=${studentData?.data?.student?.studentName}&amount=${studentData?.data?.course?.amount}`;
      //   window.location.href = url;
      window.open(url, "_self")
    } catch (error) {
      console.error('Error opening payment window:', error);
    }
  };

  useEffect(()=>{
    gatewayData.refetch();
  },[])


  const handlePayWithPhonePe = () => {
    setPaying(true);
    try {
      const url = `https://api-associate.sudikshya.co.in/phonepe/student?studentId=${studentData?.data?.student?.id}&amount=${studentData?.data?.course?.amount}`;
      //   window.location.href = url;
      window.open(url, "_blank")
    } catch (error) {
      console.error('Error opening payment window:', error);
    }
  };



  useEffect(() => {

    let timer;
    if (paying) {
      timer = setInterval(() => {
        console.log(" refetchinggggg::::GG::G::G:::G")
        response.refetch();
      }, 5000);

    }

    return () => {
      clearInterval(timer);
    };
  }, [paying]);

  // useEffect(() => {

  //   if (studentData.data.student.paid === 1) {
  //     navigate('/student'); 
  //   }
  // }, [studentData.data.student.paid, navigate]);

  if (studentData.isLoading || gatewayData.isLoading) return <Loading />
  if (studentData.isError) return <ApiErrorModal response={studentData} />


  return (
    <BachchaPicContainer withoutPic>

      {
        (studentData.data.student.paid == 0) ?
          //-----------------
          <div className="d-flex flex-column align-items-center p-5 h-100">

            <div className='text-muted align-self-start fs-5 fw-bold mb-3'>Payment summary </div>

            <h6 className='my-2'>Payment for student - {studentData.data.student.studentName}</h6>


            <div className='d-flex bg-secondary bg-opacity-50 w-100 p-2 rounded-4'>

              <div className='bg-white rounded-3' style={{ width: 50, height: 50 }}></div>

              <div className='ps-3'>
                <div className='fw-bold'>{studentData?.data?.course?.name}</div>
                <h4 className='text-primary fw-bold'>₹{studentData?.data?.course?.amount}</h4>
              </div>
            </div>

            {
              gatewayData.data[0].gateway == 'ccavenue' ?
                <>
                  {paying ? (
                    <button class='btn btn-primary btn-lg mt-auto rounded-4 w-100' type="button" disabled>
                      <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                      <span role="status"> Paying...</span>
                    </button>
                  ) : (
                    <button className='btn btn-primary btn-lg mt-auto rounded-4 w-100' onClick={handlePay}>
                      Pay Now With CCAvenue
                    </button>
                  )}

                </>
                :
                <>
                  {paying ? (
                    <button class='btn btn-primary btn-lg mt-auto rounded-4 w-100' type="button" disabled>
                      <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                      <span role="status"> Paying...</span>
                    </button>
                  ) : (
                    <button className='btn btn-primary btn-lg mt-auto rounded-4 w-100' onClick={handlePayWithPhonePe}>
                      Pay Now With PhonePe
                    </button>
                  )}

                </>

            }



      


          </div>

          //-----------------
          :

          <div className='d-flex flex-column justify-content-center align-items-center mt-5'>

            <h5> Student Already paid the Course Amount</h5>
            {/* <button onClick={navigate('/')}>Go to Users</button> */}

            <Link to={'/'} className='btn btn-primary mt-5'>Go to Users</Link>
          </div>

      }



    </BachchaPicContainer>
  )


};

export default StudentPay;
