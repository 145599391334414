import React from "react";
import { Link, useParams } from "react-router-dom";
import userImg from "../../assets/images/user-default.png";
import Loading from "../../components/ui/Loading";
import { useGetAssociateIdQuery } from "../../services/associateSlice";
import ApiErrorModal from "../../components/modals/ApiErrorModal";
import Api from "../../constants/Api";
import Icons from "../../components/ui/Icons";
import { useGetStudentQuery } from "../../services/studentSlice";
import { convertToFiveDigits } from "../../utils/ConvertToFive";

const StudentDetail = () => {
  const { id } = useParams();
  const res = useGetStudentQuery(id);

  if (res.isLoading) return <Loading />;
  if (res.isError) return <ApiErrorModal response={res} />;

  const data = res.data;

  return (
    <>
      <div className="bg-primary p-3 rounded-bottom-5">
        <div className="d-flex w-100 align-items-center px-2">
          <Link to={-1}>
            {Icons.back("text-white", { width: 32, height: 32 })}
          </Link>

          {/* <img src={Api.BASE_URL + data.associate.passPhoto} className='bg-danger rounded-circle shadow-sm' style={{ height: 50, width: 50 }} alt="" /> */}

          <div className="flex-grow-1 ps-3 text-light">
            <div>
              Student ID -{" "}
              <span className="text-warning fw-bold">
                #
                {data?.student?.serialNo
                  ? `S2S${convertToFiveDigits(data?.student?.serialNo)}`
                  : `S2S${convertToFiveDigits(data?.student?.id)}`}
              </span>
            </div>
            <h3 className="fw-bold text-primary text-white">
              {data?.student?.studentName}
            </h3>
          </div>
        </div>
      </div>

      <div className="overflow-auto flex-grow-1 w-100 d-flex flex-column">
        <div className="d-flex border-bottom">
          <div className="flex-grow-1 p-3">
            <div className="fs-13">Parent Name.</div>
            <div className="fw-bold">{data?.student?.parentName}</div>
          </div>
        </div>

        <div className="d-flex border-bottom">
          <div className="flex-grow-1 p-3">
            <div className="fs-13">Mobile No.</div>
            <div className="fw-bold">{data?.student?.mobileNumber}</div>
          </div>
        </div>

        <div className="d-flex border-bottom">
          <div className="flex-grow-1 p-3 border-end">
            <div className="fs-13">Joining Date</div>
            <div className="fw-bold">{data?.student?.createdAt}</div>
          </div>

          <div className="flex-grow-1 p-3">
            <div className="fs-13">Status</div>
            <div className="fw-bold">{data?.student?.status}</div>
          </div>
        </div>

        <div className="d-flex border-bottom">
          <div className="flex-grow-1 p-3 border-end">
            <div className="fs-13">Course</div>
            <div className="fw-bold">
              {data?.category?.name} - {data?.course?.name}
            </div>
          </div>
        </div>

        <div className="d-flex border-bottom">
          <div className="flex-grow-1 p-3 border-end">
            <div className="fs-13">Mode of Payment</div>
            <div className="fw-bold">{data?.student?.modeOfPayment}</div>
          </div>

          <div className="flex-grow-1 p-3 border-end">
            <div className="fs-13">App Student Id</div>
            <div className="fw-bold">{data?.student?.appStudentId}</div>
          </div>
        </div>
        <div className="d-flex border-bottom">
          <div className="flex-grow-1 p-3 border-end">
            <div className="fs-13">Payment Status</div>
            <div className="fw-bold">
              {data?.student?.paid == "1" ? (
                <div className="text-success">Done</div>
              ) : (
                <div className="text-danger">Due</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentDetail;
