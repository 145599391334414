import React, { useEffect, useRef, useState } from "react";
import {
  useCheckOtpMutation,
  useResendOtpMutation,
} from "../../services/associateSlice";
import OtpInput from "react-otp-input";
import BachchaPicContainer from "./BachchaPicContainer";
import SuccessModal from "../../components/modals/SuccessModal";
import Loading from "../../components/ui/Loading";
import ApiErrorModal from "../../components/modals/ApiErrorModal";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAuthToken } from "../../redux/authSlice";

const Otp = ({ phoneNumber, setStep }) => {
  const confirmInputRef = useRef(null);

  const [confirmAutoFocus, setConfirmAutoFocus] = useState(false);

  const [otp, setOtp] = useState("");

  const [otpVerified, setOtpVerified] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [loading, setLoading] = useState(false);

  const [showResendButton, setShowResendButton] = useState(false);
  const [timer, setTimer] = useState(60);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [checkOtp, checkOtpResponse] = useCheckOtpMutation({
    fixedCacheKey: "signInUser",
  });

  const [resendOtp, resendOtpRespone] = useResendOtpMutation();

  // const onSubmit = (e) => checkOtp({ mobileNumber: phoneNumber, otp, password })

  const onSubmit = (e) => {
    setLoading(true);
    checkOtp({ mobileNumber: phoneNumber, otp, password })
      .then((res) => {
        setOtpVerified(true);
        if (!res.error) {
          dispatch(setAuthToken(res.data.token));
        }
        if (confirmInputRef.current) {
          confirmInputRef.current.focus();
        }
      })
      .finally(() => {
        navigate("/");
        setLoading(false);
        // alert("Access Code Updated");
      });
  };

  const onPasswordChange = (value) => {
    setPassword(value);
    if (value.length === 4 && confirmInputRef.current) {
      confirmInputRef.current.focus();
    }
  };

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  useEffect(() => {
    if (timer === 0) {
      setShowResendButton(true);
    }
  }, [timer]);

  const handleResendOtp = () => {
    setShowResendButton(false);
    setTimer(60);
    resendOtp({ mobileNumber: phoneNumber });
  };

  if (loading) {
    return <Loading />;
  }

  if (checkOtpResponse.isSuccess)
    return <SuccessModal message={"Access Code Updated"} label={"Updated"} />;

  if (otpVerified) {
    return (
      <BachchaPicContainer withoutPic>
        <style>
          {`
                    body {
                        background: #f9e2ff;
                    }
                    .bg-header-auth{
                      background:#70018b;
                      padding: 40px 0px !important;
                      border-radius: 0px 0px 20px 20px;
                    }
                    input.form-control {
                        padding: 15px 30px;
                        border-radius: 18px !important;
                    }
                    .btn.btn-primary {
                        padding: 10px;
                    }
                    
                    `}
        </style>

        <div className="d-flex flex-column align-items-center py-5 fw-bold text-primary flex-grow-1">
          <h3 className="fw-bold">Set Access Pin</h3>
          <div className="text-muted mb-5">
            Access pin allows you to access the app
          </div>

          <h5 className="fw-bold mb-3">Create Access Pin</h5>
          <OtpInput
            value={password}
            // onChange={setPassword}
            onChange={onPasswordChange}
            numInputs={4}
            containerStyle="d-flex justify-content-center mb-3"
            renderSeparator={<span style={{ width: 10 }}></span>}
            inputType="password"
            inputStyle="form-control form-control-lg text-center p-2"
            skipDefaultStyles
            renderInput={(props) => (
              <input {...props} style={{ width: 60, height: 60 }} />
            )}
          />

          <h5 className="fw-bold mb-3">Confirm Access Pin</h5>
          <OtpInput
            value={confirmPassword}
            onChange={setConfirmPassword}
            numInputs={4}
            containerStyle="d-flex justify-content-center mb-3"
            renderSeparator={<span style={{ width: 10 }}></span>}
            inputType="password"
            inputStyle="form-control form-control-lg text-center p-2"
            skipDefaultStyles
            renderInput={(props) => (
              <input {...props} style={{ width: 60, height: 60 }} />
            )}
            // renderInput={(props) => <input {...props} style={{ width: 50 }} ref={confirmInputRef} />}
          />

          {checkOtpResponse.isLoading ? (
            <button
              className="btn btn-lg btn-primary w-75 mt-auto rounded-4 mb-3"
              type="button"
              disabled
            >
              <span
                className="spinner-border spinner-border-sm"
                aria-hidden="true"
              ></span>
              <span role="status"> Setting PIN...</span>
            </button>
          ) : (
            <button
              className="btn btn-lg btn-primary w-75 mt-auto rounded-4 mb-3"
              type="button"
              disabled={
                confirmPassword.length !== 4 || confirmPassword !== password
              }
              onClick={onSubmit}
            >
              Set PIN
            </button>
          )}
        </div>
      </BachchaPicContainer>
    );
  }

  // {(resendOtpRespone.isLoading) && <Loading />}
  if (resendOtpRespone.isLoading) return <Loading />;

  return (
    <BachchaPicContainer>
      <style>
        {`
                body {
                    background: #70018b;
                }
                `}
      </style>
      <div
        style={{
          backgroundColor: "#f9e2ff",
          position: "fixed",
          borderRadius: "20px 20px 0px 0px",
          width: "100%",
          bottom: 0,
        }}
      >
        <div className="container p-4">
          {resendOtpRespone.isSuccess && (
            <SuccessModal message={"Otp send Successfully"} />
          )}
          {resendOtpRespone.isError && (
            <ApiErrorModal response={resendOtpRespone} />
          )}

          <h3 className="text-primary fw-bold">Verify your Phone Number</h3>
          <div className="text-muted mb-3">
            OTP sent to {phoneNumber} -{" "}
            <span className="text-primary" onClick={() => setStep("login")}>
              <u>Edit</u>
            </span>
          </div>

          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            containerStyle="d-flex justify-content-center mb-3"
            renderSeparator={<span style={{ width: 10 }}></span>}
            inputType="password"
            inputStyle="form-control form-control-lg text-center"
            skipDefaultStyles
            renderInput={(props) => <input {...props} style={{ width: 50 }} />}
          />

          <button
            className="btn btn-lg btn-primary w-100 rounded-4 mb-3"
            type="button"
            disabled={otp.length !== 6}
            onClick={() => setOtpVerified(true)}
          >
            Verify
          </button>

          {/* <div className="d-flex justify-content-between">
                    <div>
                        Didn’t get OTP?

                     
                        <button className='text-primary fw-bold btn  m-0 ms-1 p-0 ' onClick={()=>resendOtp({mobileNumber:phoneNumber})}>Resend OTP</button>
                     
                     </div>
                    <div>00:20</div>
                </div> */}

          <div className="d-flex justify-content-between align-items-center">
            <div className="mx-auto">
              Didn’t get OTP?
              {showResendButton ? (
                <button
                  className="text-primary fw-bold btn  m-0 ms-1 p-0"
                  onClick={handleResendOtp}
                >
                  Resend OTP
                </button>
              ) : (
                <span className="text-muted ms-1">
                  Resend OTP in {timer} seconds
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </BachchaPicContainer>
  );
};

export default Otp;
