// import { useCheckOtpMutation, useSignOutMutation, useVerfiyPasswordMutation } from '../../services/associateSlice';
// import Icons from './Icons';

// const SignOutMobile = () => {

//     const [signOutApi] = useSignOutMutation();
//     const [verifyPassword, verifyPasswordResponse] = useVerfiyPasswordMutation({ fixedCacheKey: 'signInUser' });
//     const [checkOtp, checkOtpResponse] = useCheckOtpMutation({ fixedCacheKey: 'signInUser' });

//     const handleSignOut = (event) => {
//         event.preventDefault()
//         verifyPasswordResponse.reset()
//         checkOtpResponse.reset()
//         signOutApi();
//     }

//     return (
//         <div className='row col-11 border border-black py-0 px-3 m-3 rounded-2 fw-bold    btn btn-primary ' onClick={handleSignOut}> SIGN OUT {Icons.signOut("text-white", { width: 60, height: 60 })}</div>
//     )
// }

// export default SignOutMobile

import React, { useState } from "react";
import {
  useCheckOtpMutation,
  useSignOutMutation,
  useVerfiyPasswordMutation,
} from "../../services/associateSlice";
import Icons from "./Icons";
import ConfirmationModal from "../modals/ConfirmationModal";
import { setAuthToken } from "../../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
// Assuming ConfirmationModal is in the same directory

const SignOutMobile = () => {
  const [showModal, setShowModal] = useState(false);

  const [signOutApi] = useSignOutMutation();
  const [verifyPassword, verifyPasswordResponse] = useVerfiyPasswordMutation({
    fixedCacheKey: "signInUser",
  });
  const [checkOtp, checkOtpResponse] = useCheckOtpMutation({
    fixedCacheKey: "signInUser",
  });
  const dispatch = useDispatch();

  // const handleSignOut = (event) => {
  //     event.preventDefault();
  //     setShowModal(true);
  // }

  const handleConfirmSignOut = () => {
    verifyPasswordResponse.reset();
    checkOtpResponse.reset();
    signOutApi();

    dispatch(setAuthToken(null));
  };

  return (
    <>
      <button
        type="button"
        id="successModalButton"
        className="d-flex align-items-center p-3 mt-4 rounded-pill fw-bold btn btn-primary mx-auto"
        data-bs-toggle="modal"
        data-bs-target="#confirmationModal"
      >
        SIGN OUT{" "}
        {Icons.signOut("text-white p-0 ms-2", { width: 30, height: 30 })}
      </button>

      {/* <div className='row col-11 border border-black py-0 px-3 m-3 rounded-2 fw-bold btn btn-primary'  data-bs-toggle="modal" data-bs-target={"#" + ConfirmationModal}>
                SIGN OUT {Icons.signOut("text-white", { width: 60, height: 60 })}
            </div>
            */}
      <ConfirmationModal onConfirm={handleConfirmSignOut} />
    </>
  );
};

export default SignOutMobile;
