import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Input from "../../components/form/Input";
import {
  useGetAssociateByTokenQuery,
  usePostAssociateMutation,
  useUpdateAssociateMutation,
} from "../../services/associateSlice";
import { useGetDesignationsQuery } from "../../services/designationSlice";
import SuccessModal from "../../components/modals/SuccessModal";
import ApiErrorModal from "../../components/modals/ApiErrorModal";
import { useSelector } from "react-redux";
import Loading from "../../components/ui/Loading";
import AddharInput from "../../components/form/AddharInput";
import SelectInput from "../../components/form/SelectInput";

const GENDER = {
  MALE: "Male",
  FEMALE: "Female",
  OTHERS: "Others",
};

const QUALIFICATION = {
  PASSED_TEN: "10th Pass",
  PASSED_PLUS_TWO: "+2 Pass",
  PASSED_PLUS_THREE: "+3 Pass",
};

const RANK = {
  ZONAL_MANAGER: "Zonal Manager",
  GENERAL_MANAGER: "General Mnager",
  COMMERCIALS_MANAGER: "Commercials Manager",
};

const stateOptions = [
  { label: "Andhra Pradesh", value: "Andhra Pradesh" },
  { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
  { label: "Assam", value: "Assam" },
  { label: "Bihar", value: "Bihar" },
  { label: "Chhattisgarh", value: "Chhattisgarh" },
  { label: "Goa", value: "Goa" },
  { label: "Gujarat", value: "Gujarat" },
  { label: "Haryana", value: "Haryana" },
  { label: "Himachal Pradesh", value: "Himachal Pradesh" },
  { label: "Jharkhand", value: "Jharkhand" },
  { label: "Karnataka", value: "Karnataka" },
  { label: "Kerala", value: "Kerala" },
  { label: "Madhya Pradesh", value: "Madhya Pradesh" },
  { label: "Maharashtra", value: "Maharashtra" },
  { label: "Manipur", value: "Manipur" },
  { label: "Meghalaya", value: "Meghalaya" },
  { label: "Mizoram", value: "Mizoram" },
  { label: "Nagaland", value: "Nagaland" },
  { label: "Odisha", value: "Odisha" },
  { label: "Punjab", value: "Punjab" },
  { label: "Rajasthan", value: "Rajasthan" },
  { label: "Sikkim", value: "Sikkim" },
  { label: "Tamil Nadu", value: "Tamil Nadu" },
  { label: "Telangana", value: "Telangana" },
  { label: "Tripura", value: "Tripura" },
  { label: "Uttar Pradesh", value: "Uttar Pradesh" },
  { label: "Uttarakhand", value: "Uttarakhand" },
  { label: "West Bengal", value: "West Bengal" },
  {
    label: "Andaman and Nicobar Islands",
    value: "Andaman and Nicobar Islands",
  },
  { label: "Chandigarh", value: "Chandigarh" },
  {
    label: "Dadra and Nagar Haveli and Daman and Diu",
    value: "Dadra and Nagar Haveli and Daman and Diu",
  },
  { label: "Lakshadweep", value: "Lakshadweep" },
  { label: "Delhi", value: "Delhi" },
  { label: "Puducherry", value: "Puducherry" },
];

const SignUp = ({ refetch, mobileNumber }) => {
  const token = useSelector((state) => state.auth.authToken);
  const [postAssociate, postAssociateResponse] = useUpdateAssociateMutation();
  const getDesignations = useGetDesignationsQuery();

  const [qualification, setQualification] = useState(QUALIFICATION.PASSED_TEN);

  const [gender, setGender] = useState(GENDER.MALE);
  const [rank, setRank] = useState("");

  const activeClassName = "btn btn-primary rounded-pill px-4 py-1 me-2 mb-2";
  const inactiveClassName =
    "btn btn-outline-primary rounded-pill px-4 py-1 me-2 mb-2";

  const renderGender = (selected, current) => (
    <div
      className={selected === current ? activeClassName : inactiveClassName}
      onClick={() => setGender(current)}
    >
      {current}
    </div>
  );
  // const renderRank = (selected, current) => <div className={selected === current ? activeClassName : inactiveClassName} onClick={() => setRank(current)}>{current}</div>
  const renderQualification = (selected, current) => (
    <div
      className={selected === current ? activeClassName : inactiveClassName}
      onClick={() => setQualification(current)}
    >
      {current}
    </div>
  );

  const renderRank = (selectedId, current) => (
    <div
      className={
        selectedId === current.id ? activeClassName : inactiveClassName
      }
      onClick={() => setRank(current.id)}
    >
      {current.name}
    </div>
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    if (form.checkValidity()) {
      const body = new FormData();
      body.append("token", token);
      body.append("name", form["Associate Name"].value);
      body.append("mobileNumber", form["Mobile Number"].value);
      body.append("fatherName", form["Father’s Name"].value);
      body.append("dob", form["Date of Birth"].value);
      body.append(
        "alternateMobileNumber",
        form["Alternative Mobile Number"].value
      );
      body.append("aadharNumber", form["Aadhar Number"].value);
      body.append("gender", gender);
      body.append("qualification", qualification);
      body.append("otherQualification", form["Other Qualification"].value);
      // body.append("designationId", rank)
      // body.append("associateId", form["Sponsor’s Associate ID"].value)
      body.append("designationId", 1);
      body.append("associateId", 1);
      body.append("personalStudentId", form["Personal Student ID"].value);

      body.append(
        "address[flatNumber]",
        form["Plot/Flat Number or Building Name"].value
      );
      body.append("address[addressLine1]", form["Address Line 1"].value);
      body.append("address[landmark]", form["Landmark"].value);
      body.append("address[state]", form["State"].value);
      body.append("address[district]", form["Dist"].value);
      body.append("address[cityVillage]", form["City/Village"].value);
      body.append("address[pin]", form["Pincode"].value);

      const aadharFrontFileInput = form["Upload Aadhar Front"];
      const aadharBackFileInput = form["Upload Aadhar Back"];
      const passPhotoFileInput = form["Upload Passphoto"];

      // Check if a file is selected
      if (aadharFrontFileInput?.files?.length > 0) {
        const aadharFrontFile = aadharFrontFileInput.files[0];
        body.append("aadharFront", aadharFrontFile);
      }

      if (aadharBackFileInput?.files?.length > 0) {
        const aadharBackFile = aadharBackFileInput.files[0];
        body.append("aadharBack", aadharBackFile);
      }

      if (passPhotoFileInput?.files?.length > 0) {
        const passPhotoFile = passPhotoFileInput.files[0];
        body.append("passPhoto", passPhotoFile);
      }

      postAssociate(body).then(refetch);
    } else {
      form.classList.add("was-validated");
    }
  };

  const calculateEighteenYearsAgo = () => {
    const today = new Date();
    const eighteenYearsAgo = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    );
    return eighteenYearsAgo.toISOString().split("T")[0];
  };

  useEffect(() => {
    if (getDesignations.isFetching) {
      refetch();
    }
  }, [getDesignations.isFetching, refetch]);

  if (getDesignations.isLoading) return <Loading />;

  return (
    <>
      {postAssociateResponse.isError && (
        <ApiErrorModal response={postAssociateResponse} />
      )}

      <div className="bg-primary p-3 min-4">
        <div className="d-flex w-100 align-items-center px-2">
          <div className="flex-grow-1 text-light">
            <h3 className="text-primary text-white">Sign Up</h3>
          </div>
        </div>
      </div>

      <div className="overflow-auto p-3 flex-grow-1 w-100">
        <h5 className="text-muted mb-3">Personal Details</h5>
        <form onSubmit={handleSubmit} noValidate>
          <Input name={"Associate Name"} required />
          <Input name={"Mobile Number"} defaultValue={mobileNumber} disabled />
          <Input name={"Alternative Mobile Number"} />
          <Input name={"Father’s Name"} required />
          <Input
            name={"Date of Birth"}
            type={"date"}
            max={calculateEighteenYearsAgo()}
            required
          />
          <h6 className="text-primary mb-2 fw-bold">Gender:</h6>
          <div className="d-flex mb-3">
            {renderGender(gender, GENDER.MALE)}
            {renderGender(gender, GENDER.FEMALE)}
            {renderGender(gender, GENDER.OTHERS)}
          </div>

          <h6 className="text-primary mb-2 fw-bold">Qualifications:</h6>

          <div className="d-flex mb-3">
            {renderQualification(qualification, QUALIFICATION.PASSED_TEN)}
            {renderQualification(qualification, QUALIFICATION.PASSED_PLUS_TWO)}
            {renderQualification(
              qualification,
              QUALIFICATION.PASSED_PLUS_THREE
            )}
          </div>

          <Input name={"Other Qualification"} />

          <AddharInput
            name={"Aadhar Number"}
            type="tel"
            minLength={14}
            maxLength={14}
            required
          />

          <h5 className="text-muted mb-3 mt-5">Documents</h5>
          <Input
            name={"Upload Aadhar Front"}
            type={"file"}
            accept="image/*"
            required
          />
          <Input
            name={"Upload Aadhar Back"}
            type={"file"}
            accept="image/*"
            required
          />
          <Input
            name={"Upload Passphoto"}
            type={"file"}
            accept="image/*"
            required
          />

          <h5 className="text-muted mb-3  mt-5">Address</h5>
          <Input
            name={"Plot/Flat Number or Building Name"}
            label={"Address Line 1"}
            placeholder={"Address Line 1"}
            required
          />
          <Input
            name={"Address Line 1"}
            label={"Address Line 2"}
            placeholder={"Address Line 2"}
          />
          <Input name={"City/Village"} required />
          <Input name={"Landmark"} />
          <Input name={"Dist"} required />
          <SelectInput
            name={"State"}
            defaultValue={"Odisha"}
            options={stateOptions}
            required
          />
          <Input
            name={"Pincode"}
            type="tel"
            minLength={6}
            maxLength={6}
            required
          />

          {/* <h5 className='text-muted mb-3  mt-5'>Rank & Sponsor</h5>
                    <div className='d-flex flex-column mt-auto p-3'>
                        <div className='d-flex flex-wrap'>
                            {getDesignations?.data?.map((item) => renderRank(rank, item))}
                        </div>
                    </div> */}

          <Input name={"Personal Student ID"} />

          {/* <div className='d-flex flex-wrap'>
                        <Input name={"Sponsor’s Associate ID"} value={1} required />
                    </div> */}

          {postAssociateResponse.isLoading ? (
            <button
              class="btn btn-success rounded-4 btn-lg py-3 text-white mt-3 w-100"
              type="button"
              disabled
            >
              <span
                class="spinner-border spinner-border-sm"
                aria-hidden="true"
              ></span>
              <span role="status"> Registering...</span>
            </button>
          ) : (
            <button
              className="btn btn-success rounded-4 btn-lg py-3 text-white mt-3 w-100"
              type="submit"
            >
              Register
            </button>
          )}
        </form>
      </div>
    </>
  );
};

export default SignUp;
