import React, { useEffect, useState } from "react";
import BachchaPicContainer from "../signIn/BachchaPicContainer";
import Loading from "../../components/ui/Loading";
import ApiErrorModal from "../../components/modals/ApiErrorModal";
import {
  useGetStudentQuery,
  useGetStudentsQuery,
} from "../../services/studentSlice";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useGetAssociateIdQuery,
  useGetAssociatesQuery,
} from "../../services/associateSlice";
import { useGetPackageQuery } from "../../services/adminSlice";
import { useGetGatewayQuery } from "../../services/selectedGatewaySlice";

const AssociatePay = () => {
  const { id } = useParams();
  const packageData = useGetPackageQuery(1);
  const [paying, setPaying] = useState(false);
  const navigate = useNavigate();
  const associateData = useGetAssociateIdQuery(id);
  const response = useGetAssociatesQuery();
  const gatewayData = useGetGatewayQuery();

  const handlePay = () => {
    setPaying(true);
    try {
      const url = `https://api-associate.sudikshya.co.in/api/ccavenue?id=${associateData?.data?.associate?.id}&name=${associateData?.data?.associate?.name}&amount=${packageData?.data?.packagePrice}`;
      //   window.location.href = url;
      window.open(url, "_self");
    } catch (error) {
      console.error("Error opening payment window:", error);
    }
  };

  const handlePayWithPhonePe = () => {
    setPaying(true);
    try {
      const url = `https://api-associate.sudikshya.co.in/phonepe/associate?associateId=${associateData?.data?.associate?.id}&amount=${packageData?.data?.packagePrice}`;
      //   window.location.href = url;
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error opening payment window:", error);
    }
  };

  useEffect(() => {
    let timer;
    if (paying) {
      timer = setInterval(() => {
        console.log(" refetchinggggg::::GG::G::G:::G");
        response.refetch();
      }, 5000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [paying]);

  useEffect(() => {
    if (associateData?.data?.associate?.paid === 1) {
      navigate("/associate");
    }
  }, [associateData?.data?.associate?.paid, associateData.isSuccess]);

  if (associateData.isLoading || gatewayData.isLoading) return <Loading />;
  if (associateData.isError) return <ApiErrorModal response={associateData} />;

  if (packageData.isLoading) return <Loading />;
  if (packageData.isError) return <ApiErrorModal response={packageData} />;

  return (
    <BachchaPicContainer withoutPic>
      {associateData.data.associate.paid == 0 ? (
        //-----------------
        <div className="d-flex flex-column align-items-center p-5 h-100">
          <div className="text-muted align-self-start fs-5 fw-bold mb-3">
            Payment summary{" "}
          </div>

          <h6 className="my-2">
            Payment for Associate - {associateData.data.associate.name}
          </h6>

          <div className="d-flex bg-secondary bg-opacity-50 w-100 p-2 rounded-4">
            <div
              className="bg-white rounded-3"
              style={{ width: 50, height: 50 }}
            ></div>

            <div className="ps-3">
              <div className="fw-bold">Subscription</div>
              <h4 className="text-primary fw-bold">
                ₹{packageData?.data?.packagePrice}
              </h4>
            </div>
          </div>

          {gatewayData.data[0].gateway == "ccavenue" ? (
            <>
              {paying ? (
                <button
                  className="btn btn-primary btn-lg mt-auto rounded-4 w-100"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    aria-hidden="true"
                  ></span>
                  <span role="status"> Paying...</span>
                </button>
              ) : (
                <button
                  className="btn btn-primary btn-lg mt-auto rounded-4 w-100"
                  onClick={handlePay}
                >
                  Pay Now
                </button>
              )}
            </>
          ) : (
            <>
              {paying ? (
                <button
                  className="btn btn-primary btn-lg mt-auto rounded-4 w-100"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    aria-hidden="true"
                  ></span>
                  <span role="status"> Paying...</span>
                </button>
              ) : (
                <button
                  className="btn btn-primary btn-lg mt-auto rounded-4 w-100"
                  onClick={handlePayWithPhonePe}
                >
                  Pay Now
                </button>
              )}
            </>
          )}
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center mt-5">
          <h5> Associate Already paid the Amount</h5>
          {/* <button onClick={navigate('/')}>Go to Users</button> */}

          <Link to={"/"} className="btn btn-primary mt-5">
            Go to Users
          </Link>
        </div>
      )}
    </BachchaPicContainer>
  );
};

export default AssociatePay;
