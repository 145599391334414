import React from 'react'
import { Link } from 'react-router-dom'
import { useUpdateAssociateStatusMutation } from '../../services/associateSlice'
import SignOutMobile from '../../components/ui/SignOut'
import SignOutInactive from '../../components/ui/SignOutInactive'

const InActive = ({ id, res }) => {


  const [updateStatus, updateStatusResponse] = useUpdateAssociateStatusMutation()

  const handleClick = () => {

    updateStatus({ id, status: 'SIGN_UP_PENDING' });
    res.refetch();

  }

  return (

    <div className="h-100 w-100 d-flex flex-column px-3 align-items-center justify-content-center bg-light">
      <p className="text-center mt-2">We have identified an issue with your account. Please do not hesitate to reach out to our support team for further assistance</p>
      <p className="mt-2 fs-5 text-primary">Reach Us at 1800 103 5040</p>
      <SignOutInactive/>
      
    
    </div>

  )
}

export default InActive
