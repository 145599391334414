import React from 'react'
import { Link } from 'react-router-dom'
import { useUpdateAssociateStatusMutation } from '../../services/associateSlice'
import SignOutInactive from '../../components/ui/SignOutInactive'

const Rejected = ({ id, res }) => {


  const [updateStatus, updateStatusResponse] = useUpdateAssociateStatusMutation()

  const handleClick = () => {

    updateStatus({ id, status: 'SIGN_UP_PENDING' });
    res.refetch();

  }

  return (
    <div className='h-100 w-100 d-flex flex-column px-3  align-items-center justify-content-center bg-light'>
      <h1 className='fw-bold text-primary '>Request Rejected</h1>
      <p className='text-center mt-2'>{`Your request is rejected because ${res?.data?.associate?.reason}.`}</p>

      <button className='btn btn-primary  mb-1 mt-3' onClick={handleClick}>Sign Up Again</button>
      <SignOutInactive/>

    </div>
  )
}

export default Rejected
