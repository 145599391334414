import logo from './logo.svg';
import './App.css';
import { Provider, useSelector } from 'react-redux';
import { store } from './redux/store';
import SignIn from './pages/signIn/SignIn';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Layout from './components/layout/Layout';
import Home from './pages/home/Home';

import "./assets/scss/bootstrap.scss"
import "./assets/css/fonts.css"
import "./assets/css/global.css"
import { useEffect, useState } from 'react';
import Teams from './pages/team/Teams';
import Team from './pages/team/Team';
import ViewTeam from './pages/team/ViewTeam';
import ViewStudents from './pages/ViewStudents';
import CreateAssociate from './pages/associate/CreateAssociate';
import CreateStudent from './pages/student/CreateStudent';
import Documents from './pages/document/Documents';
import Settings from './pages/Settings';
import Associates from './pages/associate/Associates';
import Associate from './pages/associate/Associate';
import Students from './pages/student/Students';
import StudentPay from './pages/student/StudentPay';
import Leads from './pages/lead/Leads';
import CreateLead from './pages/lead/CreateLead';
import StudentDetail from './pages/student/StudentDetail';
import AssociatePay from './pages/associate/AssociatePay';
import Notifications from './pages/notification/Notifications';
import { useGetSiteInfoQuery } from './services/adminSlice';
import Api from './constants/Api';
import ContactUs from './pages/ContactUs';

import { AnimatePresence } from "framer-motion";
import { MotionConfig } from 'framer-motion';
// import SplashScreen from './components/SplashScreen';

function App() {
  const location = useLocation();
  const isSignedIn = useSelector(state => state.auth.authToken);
  // const [showSplash, setShowSplash] = useState(true);

  // const getinfo = useGetSiteInfoQuery(1)

  useEffect(() => { import('bootstrap') }, [])

  // useEffect(() => {
  //   if (getinfo.data) {
  //     document.title = getinfo.data.siteName; // Update document title
  //     const favicon = document.querySelector("link[rel='icon']");
  //     favicon.href = Api.BASE_URL + getinfo.data.favIcon; // Update favicon
  //   }
  // }, [getinfo.data]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShowSplash(false);
  //   }, 1000);

  //   return () => clearTimeout(timer);
  // }, []);

  return (

    <>
      {
        // showSplash ? (
        //   <SplashScreen />
        // ) : (
        <Routes location={location} key={location.pathname}>
          {(isSignedIn) ?
            <Route path='/' element={<Layout />}>

              <Route index element={<Home />} />
              <Route path='*' element={<Home />} />
              <Route path='settings' element={<Settings />} />
              <Route path='notifications/:id' element={<Notifications />} />
              <Route path='contactus' element={<ContactUs />} />



              <Route path='associates/:id' element={<Associates />} />
              <Route path='associate/:id' element={<Associate />} />
              <Route path='associate/pay/:id' element={<AssociatePay />} />
              <Route path='associate/create/:id' element={<CreateAssociate />} />


              <Route path='students/:id' element={<Students />} />
              <Route path='students/detail/:id' element={<StudentDetail />} />
              <Route path='students/pay/:id' element={<StudentPay />} />
              <Route path='student/create/:id' element={<CreateStudent />} />

              <Route path='documents'>
                <Route index element={<Documents />} />
              </Route>

              <Route path='leads'>
                <Route index element={<Leads />} />
                <Route path='create/:id' element={<CreateLead />} />

              </Route>


            </Route>
            :
            <Route path='/'>
              <Route index element={<SignIn />} />
              <Route path='*' element={<SignIn />} />

            </Route>
          }

        </Routes>
        // )
      }
    </>
  )

}

export default App;
