

import React from 'react'
import { Link } from 'react-router-dom'
import Input from '../form/Input'
import OTPInput from 'react-otp-input';

const ShortcutModal = ({ otpSent, otpVerified, otp, setOtp, handleVerifyOtp, handleSendOtp }) => {

    const modalId = "ShortcutModal"

    const handleChange = (otp) => setOtp(otp.replace(/\D/g, ''))

    return (
        <>
            <Link type="button" id='shortcutButton' className="btn btn-primary text-white rounded-3 px-3 mx-2 d-none float-end" data-bs-toggle="modal" data-bs-target={"#ShortcutModal"}>
                Edit
            </Link>



            <div className="modal fade" id={modalId} tabindex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl ">
                    <form className="modal-content rounded-4  " style={{ height: 250 }} >

                        <div className="modal-header d-flex justify-content-center p-0">
                            <h1 className="modal-title fs-5 bg-primary text-white px-3 py-1 rounded-bottom-4 mb-3" id={modalId + "Label"}>Verify Otp</h1>

                        </div>
                        <div className="modal-body" >
                            <div className=' d-flex justify-content-center align-items-center'>

                                {otpSent && !otpVerified && (
                                    <div className='mt-3 '>

                                        <OTPInput
                                            value={otp}
                                            onChange={handleChange}
                                            numInputs={6}
                                            containerStyle="d-flex justify-content-center mb-3 "
                                            renderSeparator={<span style={{ width: 10 }}></span>}
                                            inputType={'password'}
                                            inputStyle="form-control form-control-lg text-center p-3"
                                            skipDefaultStyles
                                            renderInput={(props) => (
                                                <input
                                                    {...props}
                                                    style={{ width: 50, height: 50 }}

                                                />
                                            )}
                                        />


                                        {/* <Input name={"OTP"} type="text" value={otp} onChange={(e) => setOtp(e.target.value)} required /> */}

                                    </div>
                                )}

                                {
                                    otpVerified && <h4 className=' mt-3 fw-bold text-success'>Otp Verified Successfully</h4>
                                }

                            </div>
                        </div>
                        <div className="modal-footer">


                            { !otpVerified?
                                <>

                                    <button type='button' className='btn btn-primary btn-sm me-2 ' onClick={handleVerifyOtp}>Verify OTP</button>
                                    <button type='button' className="btn btn-primary btn-sm " fdprocessedid="carraw" onClick={handleSendOtp}>Resend</button>
                                </>
                                :
                                <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ShortcutModal