import React from 'react'

const Pending = () => {
  return (
    <div className='h-100 w-100 d-flex flex-column px-3  align-items-center justify-content-center bg-light'>
      <h1 className='fw-bold text-primary '>Request Pending</h1>
      <p className='text-center mt-2'>Your request is pending for approval right now. Kindly check after sometime.</p>
    </div>
  )
}

export default Pending
