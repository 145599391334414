import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Input from "../../components/form/Input";
import SelectInput from "../../components/form/SelectInput";
import { useGetAssociatesQuery } from "../../services/associateSlice";
import { useGetProductsQuery } from "../../services/productSlice";
import { useGetCategoriesQuery } from "../../services/categorySlice";
import { usePostStudentMutation } from "../../services/studentSlice";
import Loading from "../../components/ui/Loading";
import ApiErrorModal from "../../components/modals/ApiErrorModal";
import SuccessModal from "../../components/modals/SuccessModal";
import Icons from "../../components/ui/Icons";
import { ToastContainer, toast, Bounce } from "react-toastify";
const CreateStudent = () => {
  const { id } = useParams();

  const [selectValue, setSelectValue] = useState("");

  const [postStudent, postStudentResponse] = usePostStudentMutation();

  const navigate = useNavigate();

  const sponserData = useGetAssociatesQuery();
  const productData = useGetProductsQuery();
  const categoryData = useGetCategoriesQuery();

  const btnClassName =
    "btn btn-success rounded-pill btn-lg px-5 text-white mt-5 w-100";

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    if (form.checkValidity()) {
      const body = {
        studentName: form["Student Name"].value,
        mobileNumber: form["Mobile Number"].value,
        associateId: form["SponsorName"].value,
        adminId: 0, //current admin id from login
        appStudentId: form["App Student ID"].value || " ",
        courseId: form["Product"].value,
        modeOfPayment: "Online",
        parentName: form["Parent Name"].value,
        // refId: form["Ref. Id"]?.value || ' '
      };
      postStudent(body).then((res) => {
        if (!res.error) navigate(`../../students/pay/${res.data.studentId}`);
        // navigate(`../../associate/pay/${res.data.associateId}`)
      });
    } else {
      form.classList.add("was-validated");
    }
  };

  if (sponserData.isLoading || productData.isLoading || categoryData.isLoading)
    return <Loading />;
  if (sponserData.isError) return <ApiErrorModal response={sponserData} />;
  if (productData.isError) return <ApiErrorModal response={productData} />;
  if (categoryData.isError) return <ApiErrorModal response={categoryData} />;

  return (
    <>
      {postStudentResponse.isError && (
        <ApiErrorModal response={postStudentResponse} />
      )}
      {/* {postStudentResponse.isSuccess && <SuccessModal message={"Successfully created."} />} */}
      {postStudentResponse.isSuccess &&
        toast("Student Created SuccessFully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        })}

      <div className="bg-primary p-3 min-4">
        <div className="d-flex w-100 align-items-center px-2">
          <Link to={-1}>
            {Icons.back("text-white", { width: 32, height: 32 })}
          </Link>
          <div className="flex-grow-1 ps-3 text-light">
            <h3 className="text-primary text-white">Create Student</h3>
            <div>
              For{" "}
              {
                sponserData.data.find((item) => item.associate.id === id)
                  .associate.name
              }
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-auto p-3 flex-grow-1 w-100">
        <h5 className="text-primary mb-3 fw-bold">Personal Details</h5>
        <form className="pb-5" onSubmit={handleSubmit} noValidate>
          <Input name={"Student Name"} required />
          <Input containerClass="" name={"Parent Name"} required />
          <Input
            name={"Mobile Number"}
            type="tel"
            minLength={10}
            maxLength={10}
            required
          />
          <p className="text-muted ms-2 mb-1 d-none">Sponsor</p>
          <SelectInput
            name="SponsorName"
            options={
              sponserData?.data?.map((sponsor) => ({
                value: sponsor.associate.id,
                label:
                  sponsor.associate.serialNo + " " + sponsor.associate.name,
              })) || []
            }
            value={
              sponserData.data.find((item) => item.associate.id === id)
                .associate.id
            }
            className="d-none"
            disabled
          />
          <Input name={"App Student ID"} />
          <h5 className="text-primary mb-3 fw-bold">Product Details</h5>
          <p className="text-muted ms-2 mb-1">Category</p>
          <SelectInput
            name="Category"
            options={[
              ...[
                {
                  value: "",
                  label: "Select",
                },
              ],
              ...categoryData?.data?.map((item) => ({
                value: item.id,
                label: item.name,
              })),
            ]}
            value={selectValue}
            onChange={(e) => setSelectValue(e.target.value)}
            required
          />

          <p className="text-muted ms-2 mb-1">Class</p>
          <SelectInput
            name="Product"
            options={[
              ...[
                {
                  value: "",
                  label: "Select",
                },
              ],
              ...productData?.data
                ?.filter((item) => item.course.categoryId === selectValue)
                ?.map((item) => ({
                  value: item.course.id,
                  label: item.course.name,
                })),
            ]}
            required
          />

          {/* <h5 className='text-primary mb-3 fw-bold'>Invoice Details</h5> */}
          {/* <Input name={"Mode of Payment"} required /> */}
          {/* <Input name={"Ref. Id"} /> */}
          {postStudentResponse.isLoading ? (
            <button class={btnClassName} type="button" disabled>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>{" "}
              Creating...
            </button>
          ) : (
            <button type="submit" className={btnClassName}>
              Create
            </button>
          )}

          <div className="py-5 my-5"></div>
        </form>
      </div>
    </>
  );
};

export default CreateStudent;
