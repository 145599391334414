import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import userImg from "../../assets/images/user-default.png";
import {
  useGetAssociateByAssociateQuery,
  useGetAssociateIdQuery,
} from "../../services/associateSlice";
import Loading from "../../components/ui/Loading";
import ApiErrorModal from "../../components/modals/ApiErrorModal";
import Api from "../../constants/Api";
import Icons from "../../components/ui/Icons";
import SelectInput from "../../components/form/SelectInput";
import InputSearch from "../../components/form/InputSearch";
import { convertToFiveDigits } from "../../utils/ConvertToFive";
import { useGetDesignationsQuery } from "../../services/designationSlice";

const Associates = () => {
  const { id } = useParams();
  const currentRes = useGetAssociateIdQuery(id);
  const desigRes = useGetDesignationsQuery();
  const response = useGetAssociateByAssociateQuery(id);

  const [searchValue, setSearchyValue] = useState("");
  const [selectValue, setSelectValue] = useState("");

  const renderItem = (item) => (
    <Link
      className="d-flex w-100 align-items-center px-3 py-4 border-bottom border-2 text-decoration-none text-dark"
      to={"/associate/" + item.id}
    >
      <img
        src={Api.BASE_URL + item.passPhoto}
        className="bg-danger rounded-circle shadow-sm"
        style={{ height: 50, width: 50 }}
        alt=""
      />

      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="flex-grow-1 ps-3 fw-bold">
          <div>
            Associate ID -{" "}
            <span className="text-warning">{`S3A${convertToFiveDigits(
              item?.serialNo
            )}`}</span>
          </div>
          <h3 className="fw-bold text-primary">{item.name}</h3>
          <small className="fw-bold text-secondary">
            {desigRes?.data?.find((i) => i.id == item.designationId)?.name}
          </small>
        </div>
        {/* <div className="fw-bold me-3">
          {item.isActive == "1" ? "Active" : "InActive"}
        </div> */}

        {item.paid != 1 && (
          <Link
            className="btn btn-primary"
            to={`../../associate/pay/${item.id}`}
          >
            Pay
          </Link>
        )}
      </div>
    </Link>
  );

  if (response.isLoading || currentRes.isLoading || desigRes.isLoading)
    return <Loading />;
  if (response.isError) return <ApiErrorModal response={response} />;
  if (currentRes.isError) return <ApiErrorModal response={currentRes} />;
  if (desigRes.isError) return <ApiErrorModal response={currentRes} />;

  return (
    <div>
      <div className="bg-primary p-3 min-4">
        <div className="d-flex w-100 align-items-center px-2">
          <Link to={-1}>
            {Icons.back("text-white", { width: 32, height: 32 })}
          </Link>

          <div className="flex-grow-1 ps-3 text-light">
            <div>{currentRes.data.associate.name}</div>
            <h3 className="fw-bold text-primary text-white">Team</h3>
          </div>

          <Link to={"/associate/create/" + id}>
            {Icons.create("text-white", { width: 40, height: 40 })}
          </Link>
        </div>
      </div>

      <div className="row row-cols-2 row-cols-lg-3 g-3 mx-2 my-3">
        <div className="col d-none">
          <SelectInput
            name={"Select Status"}
            noLabel
            options={[
              { value: "", label: "Select" },
              { value: "Active", label: "Active" },
              { value: "InActive", label: "InActive" },
            ]}
            value={selectValue}
            onChange={(e) => setSelectValue(e.target.value)}
            containerClass={""}
            required
          />
        </div>

        <div className="col-12">
          <InputSearch
            name={"Search Associates"}
            containerClass={""}
            value={searchValue}
            onChange={(e) => setSearchyValue(e.target.value)}
          />
        </div>
      </div>

      {/* <div className="d-flex">
        <div className='text-center my-2 '>All Associates: {response.data.filter((item => item.status == 'Approved')).length}</div>
        <div className='text-center my-2'>Active associates: {response?.data?.filter((item => item.status == 'Approved'))?.filter((item) => item.isActive)?.length}</div>
        <div className='text-center my-2'>Inactive associates: {response.data.filter((item => item.status == 'Approved')).length}</div>
      </div> */}

      <div className="overflow-auto flex-grow-1 w-100">
        {response?.data
          .filter((item) => {
            if (searchValue?.length === 0 && selectValue?.length === 0)
              return true;

            const isSearchMatch =
              searchValue?.length > 0 &&
              (item?.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                `S3A${convertToFiveDigits(item?.serialNo)}`
                  .toLowerCase()
                  .includes(searchValue.toLowerCase()));

            let isProductMatch = selectValue?.length > 0;

            if (isProductMatch) {
              switch (selectValue) {
                case "Active":
                  isProductMatch = item.isActive == 1;
                  break;
                case "InActive":
                  isProductMatch = item.isActive == 0;
                  break;
                default:
                  isProductMatch = true;
              }
            }

            if (searchValue?.length === 0) return isProductMatch;
            if (selectValue?.length === 0) return isSearchMatch;

            return isProductMatch && isSearchMatch;
          })
          ?.filter((item) => item.status == "Approved")
          ?.map(renderItem)}
      </div>
    </div>
  );
};

export default Associates;
