import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";


export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getProducts: builder.query({ query: () => `/course`, keepUnusedDataFor: 0 }),
        postProduct: builder.mutation({ query: (body) => ({ url: `/course/create`, method: 'POST', body }) }),
        getStaffProduct: builder.query({ query: () => `/admin/getPackage?id=1`, keepUnusedDataFor: 0 }),
    }),
})

export const { useGetProductsQuery, usePostProductMutation, useGetStaffProductQuery } = extendedApiSlice
